import { useEffect } from 'react'
import { getQueryString } from '../../utils/utils'
import Request from '../../utils/request'
import Path from '../../utils/path'

const Barbie = props => {
  // 业主token
  const token = getQueryString('token')
  useEffect(async () => {
    // 获取本地存储登录数据
    let name = '', houseName = '', phone = '';
    if (token) {
      let res = await Request.get(Path.getUserInfo, { headers: { authorization: 'Bearer ' + token } });
      if (res.success) {
        const data = res.data
        phone = data?.phone
        name = data?.name
      }
      res = await Request.get(Path.getHouseList, { headers: { authorization: 'Bearer ' + token } });
      if (res.success) {
        if (res.data.length > 0) {
          let houseInfo = null
          if (res.data[0]?.status.code == '1') {
            houseInfo = res.data[0]?.houseInfo
          }
          res.data.forEach(item => {
            if (item.status.code == '1' && item.type.code == '1') {
              houseInfo = item.houseInfo
            }
          })
          houseName =  (houseInfo?.communityName || '') + (houseInfo?.buildingName || '') + (houseInfo?.unitName || '') + (houseInfo?.name || '')
        }
      }
    }

    let url = 'https://cxhwj.actiiot.com/pages/question/index?mid=6201e6d899667c5ce29c696a'
    if (name != undefined && name!='') {
      url = url + '&q4='
      url = url + name
    }
    if (houseName != undefined && houseName!='') {
      url = url + '&q3='
      url = url + houseName
    }
    if (phone != undefined && phone!='') {
      url = url + '&q5='
      url = url + phone
    }

    console.log('url: ', url)

    window.location.href = url

  }, [])
  return (
    <div>
    </div>
  )
}

export default Barbie
