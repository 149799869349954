import { useEffect, useState } from 'react';
import { List, Modal } from 'antd-mobile';
import Request from '../../utils/request'
import Path from '../../utils/path'
import Down from '../../images/down.png'
import Style from './index.module.css'

const Item = List.Item;

const HouseModal = ({onlyHouse, communityData, onClose, onSubmit}) => {
  // console.log(communityData);
  // console.log(onlyHouse);
  const [myHouse, setMyHouse] = useState(onlyHouse || {})
  const [show, setShow] = useState(true)
  const [clickItem, setClickItem] = useState('community')
  
  const [nodeData, setNodeData] = useState(communityData || [])
  useEffect(() => {
    // 有房间号的情况 一般是编辑
    if(myHouse && myHouse?.houseInfo?.name) {
      getNodeInfo(myHouse.houseInfo.floorId, 6)
    } else {
      if(!communityData) {
        getNodeInfo(myHouse.houseInfo?.communityId, 3)
      }
    }
  }, [])

  useEffect(() => {
    const typeArr = {3:'building', 4: 'unit', 5: 'floor', 6: 'house'}
    if (nodeData.length > 0) {
      if(nodeData[0].type) {
        setClickItem(typeArr[nodeData[0].type])
      } else {
        setClickItem('community')
      }
    }
  }, [nodeData])

  const clickNode = (type, nodeType) => {
    if(type == 'community') {
      setNodeData(communityData)
    } else {
      let nodeId = ''
      if(type == 'building') {
        nodeId = myHouse.houseInfo?.communityId
      } else if (type == 'unit') {
        nodeId = myHouse.houseInfo?.buildingId
      } else if (type == 'floor') {
        nodeId = myHouse.houseInfo?.unitId
      } else if (type == 'house') {
        nodeId = myHouse.houseInfo?.floorId
      }
      nodeId && getNodeInfo(nodeId, nodeType)
    }
  }

  const getNodeInfo = (nodeId, nodeType) => {
    Request.get(Path.getNodeTypeAllData, { params: { nodeType, parentIds: nodeId } }).then(v => {
      if (v.success && v.data) {
        setNodeData(v.data)
      }
    })
  }

  const clickNodeValue = item => {
    if(item.type) {
      if(item.type == 6) {  // 房间
        setMyHouse({id: myHouse.id, houseInfo:{...item}})
        onSubmit && onSubmit({id: myHouse.id, houseInfo:{...item}})
      } else {
        const data = {id: myHouse.id, houseInfo:{...item}}
        delete data.houseInfo?.name
        setMyHouse(data)
        getNodeInfo(item.id, Number(item.type) + 1)
      }
    } else {      // 项目
      setMyHouse({id:myHouse.id, houseInfo:{communityName: item.name, communityId: item.id}})
      getNodeInfo(item.id, 3)
    }
  }
  const clickShow = () => {
    setShow(false)
    onClose && onClose()
  }
  return (
    <Modal
      popup
      visible={show}
      onClose={()=>clickShow()}
      animationType="slide-up"
    >
      <div className={Style.myDivStyle}>
        <div style={{ marginTop: '15px', marginBottom: '20px'}}>{`选择${communityData?'小区/':''}楼栋/单元/房号`}</div>
        {communityData && <div className={Style.topDivStyle} onClick={()=>clickNode('community')}>
          <div style={clickItem == 'community'?{color: '#00AAFF'}:!myHouse?.houseInfo?.communityName?{color: '#666'}:{}}>{myHouse?.houseInfo?.communityName || '选择项目'}</div>
          <img src={Down} style={{ width: '12px', height: '7px' }} />
        </div>}

        <div className={Style.topDivStyle} onClick={()=>clickNode('building', 3)}>
          <div style={clickItem == 'building'?{color: '#00AAFF'}:!myHouse?.houseInfo?.buildingName?{color: '#666'}:{}}>{myHouse?.houseInfo?.buildingName || '选择楼栋'}</div>
          <img src={Down} style={{ width: '12px', height: '7px' }} />
        </div>

        <div className={Style.topDivStyle} onClick={()=>clickNode('unit', 4)}>
          <div style={clickItem == 'unit'?{color: '#00AAFF'}:!myHouse?.houseInfo?.unitName?{color: '#666'}:{}}>{myHouse?.houseInfo?.unitName || '选择单元'}</div>
          <img src={Down} style={{ width: '12px', height: '7px' }} />
        </div>

        <div className={Style.topDivStyle} onClick={()=>clickNode('floor', 5)}>
          <div style={clickItem == 'floor'?{color: '#00AAFF'}:!myHouse?.houseInfo?.floorName?{color: '#666'}:{}}>{myHouse?.houseInfo?.floorName || '选择楼层'}</div>
          <img src={Down} style={{ width: '12px', height: '7px' }} />
        </div>

        <div className={Style.topDivStyle} onClick={()=>clickNode('house', 6)}>
          <div style={clickItem == 'house'?{color: '#00AAFF'}:!myHouse?.houseInfo?.name?{color: '#666'}:{}}>{myHouse?.houseInfo?.name || '选择房间'}</div>
          <img src={Down} style={{ width: '12px', height: '7px' }} />
        </div>

        <div style={{height: '1px', background: '#E0E0E0', marginBottom: '15px'}}></div>

        <div style={{height: '200px', overflow: 'auto', textAlign: 'left'}}>
          {nodeData.map(item=>(
            <div key={item.id} style={{marginBottom: '25px'}} onClick={()=>clickNodeValue(item)}>{item.name}</div>
          ))}
        </div>
      </div>
    </Modal>
  )
}

export default HouseModal;
