
const userUrl = '/api/cxh-user-service'
const authUrl = '/api/cxh-auth-service'
const estateUrl = '/api/cxh-estate-service'
const qywxUrl = '/api/cxh-qywx-cp-service'
const pushUrl = '/api/cxh-push-service'
const payfeeUrl = '/api/cxh-payfee-service'
const workstateUrl = '/api/cxh-workstate-service'
const deviceUrl = '/api/cxh-device-service'
const informationUrl = '/api/cxh-information-service'
const fileUrl = `/api/cxh-file-service`
const yunUrl = `/api/cxh-yun-service`

export default {
  upload: `${fileUrl}/v1/upload/upload`, // 上传图片
  authGetDictList: `${authUrl}/v1/dict/getDictList`, // auth字典表
  workstateGetDictList: `${workstateUrl}/v1/dict/getDictList`, // workstate字典表
  qywxUrlGetDictList: `${qywxUrl}/v1/dict/getDictList`, // 企业微信字典表

  getConfig: `${userUrl}/v1/wechat/getConfig`, // 获取企业微信信息
  jsapiSign: `${qywxUrl}/v1/wechatcp/oauth/agent/create/jsapi_sign`, // 企业微信config注入
  agentJsapiSign: `${qywxUrl}/v1/wechatcp/oauth/agent/create/agent_jsapi_sign`, // 企业微信agentConfig注入
  getUserInfoByPhone: `${userUrl}/v1/m/user/getUserInfoByPhone`, //根据手机号码获取个人信息
  getHouseList: `${authUrl}/v1/m/owner/getHouseList`, // 根据手机号获取房间列表
  getCommunityIds: `${authUrl}/v1/m/employee/getCommunityIds`, // 获取管家管的小区
  getCommunityData: `${estateUrl}/v2/m/community/getCommunityData`, // 获取所有小区
  getNodeTypeAllData: `${estateUrl}/v1/node/getNodeTypeAllData`, // 获取项目楼栋房间节点信息
  getTotalNodeInfo: `${estateUrl}/v2/m/totalNode/getTotalNodeInfo`, // 获取项目楼栋下的房间
  saveOwnerInfoQywx: `${authUrl}/v1/m/owner/saveOwnerInfoQywx`, // 管家保存业主信息
  getTotalOwnerWorkOrder: `${workstateUrl}/v1/m/totalWorkOrder/getTotalOwnerWorkOrder`, // 管家查看业主的报修统计
  getOwnerRepairInfo: `${workstateUrl}/v1/repair/getOwnerRepairInfo`, // 管家查看业主的报修记录

  getUserInfo: `${userUrl}/v1/m/user/getUserInfo`, // 获取用户信息
  // resetPhone: `${userUrl}/v1/m/user/resetPhone`, // 更换手机号
  authBindAndLogin: `${userUrl}/v1/m/user/authBindAndLogin`, // 绑定手机号
  getExternalContact: `${qywxUrl}/v1/wechatcp/externalcontact/getExternalContact`, // 企业微信管家获取业主信息
  getUserInfoByUnionId: `${userUrl}/v1/m/user/getUserInfoByUnionId`, // 根据unitid获取用户信息

  sendMCode: `${pushUrl}/v1/sms/sendMCode`, // 获取验证码
  createOwner: `${authUrl}/v1/m/owner/createOwner`, // 绑定房屋
  getUserList: `${authUrl}/v1/m/owner/getUserList`, // 获取房屋下的所有人员
  
  deleteMyHouse: `${authUrl}/v1/m/owner/delete`, // 删除业主房屋绑定
  getFaceDevice: `${deviceUrl}/v1/m/device/getFaceDevice`, // 判断小区是否有设备
  selectWXFaceByHouseId: `${deviceUrl}/v2/m/face/selectWXFaceByHouseId`, // 业主查询人脸
  deleteFace: `${deviceUrl}/v2/m/face/delete`, // 删除人脸
  addWXCommunity: `${deviceUrl}/v2/m/face/addWXCommunity`, // 新增人脸

  getFeeByUser: `${payfeeUrl}/v1/m/fee/getFeeByUserQywx`, // 用户缴费信息
  getHistoricalQywx: `${payfeeUrl}/v1/m/fee/getHistoricalQywx`, // 用户缴费记录
  getPayHouseList: `${payfeeUrl}/v1/m/fee/getHouseListQywx`, // 根据房屋获取用户缴费信息
  createChargeBill: `${payfeeUrl}/v1/m/fee/createChargeBill`, // 申请支付物业缴费账单
  getTotalMonthTask: `${payfeeUrl}/v1/m/totalBill/getTotalMonthTask`, // 业主缴费统计
  getTotalBuildOwner: `${authUrl}/v2/m/totalNode/getTotalBuildOwner`, // 催缴列表
  getEmployeeByBuild: `${authUrl}/v1/m/employee/getEmployeeByBuild`, // 管家管理的楼栋
  removeContractNo: `${payfeeUrl}/v1/res/removeContractNo`, // 取消支付
  getOrderPrintList: `${payfeeUrl}/v1/m/billPrint/getOrderPrintList`, // 开票记录
  getHistoricalOrder: `${payfeeUrl}/v1/m/billPrint/getHistoricalOrder`, // 已开票记录
  getOrderPrintBySn: `${payfeeUrl}/v1/m/billPrint/getOrderPrintBySn`, // 发票详情
  sendOrderEmail: `${payfeeUrl}/v1/m/billPrint/sendOrderEmail`, // 重新开发票
  createBillPrintQywx: `${payfeeUrl}/v1/m/billPrint/createBillPrintQywx`, // 申请开票
  
  getEmployeeById: `${userUrl}/v1/m/employee/getEmployeeById`, // 根据管家id获取管家token
  
  getEmployeeReviewList: `${authUrl}/v1/m/employee/getEmployeeReviewList`, // 根据管家id获取用户待审核房屋
  approveQywx: `${authUrl}/v1/m/employee/approveQywx`, // 通过和拒绝房屋审批

  getMsgTemplate: `${qywxUrl}/v1/wechatcp/message/getMsgTemplate`, // 通过和拒绝房屋审批

  // 工单
  getWorkOrderList: `${workstateUrl}/v1/repair/getList`, // 工单列表查询
  addEnclosure: `${workstateUrl}/v1/case/addEnclosure`, // 附件id
  getHouseOwnerInfo: `${authUrl}/v1/m/owner/getHouseOwnerInfo`, // 根据房间id获取房间用户
  getCommunityByEmployee: `${authUrl}/v1/m/employee/getCommunityByEmployee`, // 根据项目id获取接单人员
  createByAdmin: `${workstateUrl}/v1/repair/createByAdmin`, // 创建报修工单(管家提报)
  getDetail: `${workstateUrl}/v1/repair/getDetail`, // 工单详情
  workNext: `${workstateUrl}/v1/repair/next`, // 工单下一步处理
  getRepairListInfo: `${workstateUrl}/v1/repair/getRepairListInfo`, // 管家查看代客报修历史记录
  
  // 管家端首页
  getEmpRole: `${authUrl}/v2/m/totalNode/getEmpRole`, // 判断管家权限
  getTotalNodeBuild: `${authUrl}/v2/m/totalNode/getTotalNodeBuild`, // 管理房屋数据统计
  getTotalHouseUser: `${authUrl}/v2/m/totalNode/getTotalHouseUser`, // 管理我的数据统计
  getTotalWorkOrderInfo: `${workstateUrl}/v1/m/totalWorkOrder/getTotalWorkOrderInfo`, // 工单数据统计
  
  // 业主提报工单
  createByOwner: `${workstateUrl}/v1/repair/create`, // 业主提交工单
  
  // 物业瞬间
  addArticleByEmployee: `${informationUrl}/v1/m/infoMoment/add`, // 新增物业瞬间
  searchMy: `${informationUrl}/v1/m/infoMoment/searchMy`, // 我的物业瞬间
  deleteInfoMoment: `${informationUrl}/v1/infoMoment/delete`, // 删除物业瞬间
  searchByIdInfoMoment: `${informationUrl}/v1/m/infoMoment/searchById`, // 根据id查询物业瞬间详情
  updateMoment: `${informationUrl}/v1/m/infoMoment/updateMoment`, // 修改物业瞬间

  createUserByUnionId: `${userUrl}/v1/m/user/createUserByUnionId`, // 根据unionId 创建用户
  
  // 管理端
  getTotalManagerNode: `${authUrl}/v2/m/totalNode/getTotalManagerNode`, // 管理人员的项目和房屋总数
  getTotalHouseManager: `${authUrl}/v2/m/totalNode/getTotalHouseManager`, // 管理人员的团队数据总览
  getProjectGeneralize: `${authUrl}/v2/m/totalNode/getProjectGeneralize`, // 管理人员的项目概况
  
  // 云之家
  getLoginUserInfo: `${userUrl}/v1/employee/getLoginUserInfo`, // 获取管家手机号
  yunLogin: `${yunUrl}/v1/yun/liteAPP/login`, // 云之家登录
  getMainVersionByEid: `${yunUrl}/v1/yun/liteAPP/getMainVersionByEid`, // 获取主版本
  lappAccess: `${yunUrl}/v1/yun/liteAPP/lappAccess`, // 获取轻应用外链
}
