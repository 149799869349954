import { useEffect, useState, useRef } from 'react'
import { useHistory } from "react-router-dom"
import { ImageViewer, Toast, Dialog, Button } from 'antd-mobile-v5';
import { CameraOutline, CloseCircleFill } from 'antd-mobile-icons'
import Cropper from 'react-cropper'
import 'cropperjs/dist/cropper.css'
import { getQueryString } from '../../utils/utils'
import Request from '../../utils/request'
import Path from '../../utils/path'

const FaceAdd = ({ location }) => {
  const wx = window.jWeixin
  const { state } = location
  let history = useHistory()
  // 业主token
  const token = getQueryString('token')

  document.title = '新增人脸'

  const [faceMyData, setFaceMyData] = useState({})
  const [faceSelfData, setFaceSelfData] = useState([])
  const [base64, setBase64] = useState({})
  const cropperRef = useRef()
  useEffect(() => {
    const data = state?.data
    if (data && data.length > 0) {
      setFaceMyData(data[0])
      const a = data.filter((item, index) => {
        if (item && index > 0 && index < 3) {
          return item
        }
      })
      setFaceSelfData(a)
    }
  }, [])

  const deleteImg = (id, type) => {
    Dialog.confirm({
      title: '提示',
      content: '确定删除照片?',
      onConfirm: () => {
        Request.post(Path.deleteFace, { id }).then(v => {
          if (v.success) {
            if (type == 'my') {
              setFaceMyData({})
            } else {
              const d = faceSelfData.filter(item => item.id != id)
              setFaceSelfData(d)
            }
            Toast.show('照片删除成功!', 1)
          }
        })
      }
    })
  }

  const cameraPhoto = type => {
    wx.chooseImage({
      count: 1, // 默认9
      sizeType: ['original', 'compressed'], // 指定是原图还是压缩图，默认都有
      sourceType: ['camera'], // 指定来源是相册还是相机，默认都有
      success: function (res) {
        wx.getLocalImgData({
          localId: res.localIds[0], // 图片的localID
          success: function (res) {
            const localData = res.localData  // localData是图片的base64数据
            let imageBase64 = ''
            if (localData.indexOf('data:image') == 0) {
              imageBase64 = localData
            } else {
              imageBase64 = 'data:image/jpeg;base64,' + localData.replace(/\n/g, '')
            }
            setBase64({ url: imageBase64, type })
          }
        })
      }
    })
  }
  const handleSubmit = () => {
    cropperRef.current.cropper.getCroppedCanvas().toBlob(blob => {
      const reader = new FileReader()
      reader.readAsDataURL(blob)
      reader.onload = e => { // onload 事件在图片加载完成后立即执行。
        let faceUrl = reader.result
        if (reader.result.indexOf('data:image/jpeg;base64,') > -1) {
          faceUrl = reader.result.replace('data:image/jpeg;base64,', '')
        }
        const params = {
          count: 3,
          faceUrl,
          houseId: state?.houseId,
          type: base64?.type
        }
        Request.post(Path.addWXCommunity, params, { headers: { authorization: 'Bearer ' + token } }).then(v => {
          if (v.success && v.data) {
            if (base64?.type == 0) {
              setFaceMyData({ faceUrl: v.data.faceUrl, id: v.data.id })
            } else {
              setFaceSelfData([...faceSelfData, { faceUrl: v.data.faceUrl, id: v.data.id }])
            }
            setBase64({})
          }
        })
      }
    }, 'image/jpeg', 0.5)
  }
  return (
    <>
      {!base64?.url ? <div style={{ height: '100vh', background: '#fff', padding: '0 15px' }}>
        <div style={{ paddingTop: '15px', fontSize: '14px' }}>本人人脸照片录入</div>
        <div style={{ width: '100px', height: '100px', border: '1px solid #ddd', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px' }}>
          {faceMyData?.faceUrl ?
            <div style={{ position: 'relative' }}>
              <img style={{ width: '95px', height: '95px' }} src={faceMyData?.faceUrl} onClick={() => ImageViewer.show({ image: faceMyData?.faceUrl })} />
              <CloseCircleFill color='#da2b17' fontSize={18} style={{ position: 'absolute', top: '-8px', right: '-10px' }} onClick={() => deleteImg(faceMyData?.id, 'my')} />
            </div>
            : <CameraOutline fontSize={36} onClick={() => cameraPhoto(0)} />}
        </div>
        <div style={{ marginTop: '30px', fontSize: '14px' }}>家属人脸照片录入 (最多添加2个家属人脸)</div>
        <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
          {
            faceSelfData.map((item, index) => (
              <div key={index} style={{ position: 'relative', marginRight: '15px' }}>
                <img style={{ width: '100px', height: '100px' }} src={item?.faceUrl} onClick={() => ImageViewer.show({ image: item?.faceUrl })} />
                <CloseCircleFill color='#da2b17' fontSize={18} style={{ position: 'absolute', top: '-8px', right: '-10px' }} onClick={() => deleteImg(item.id, 'self')} />
              </div>
            ))
          }
          {
            faceSelfData.length < 2 &&
            <div style={{ width: '100px', height: '100px', border: '1px solid #ddd', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <CameraOutline fontSize={36} onClick={() => cameraPhoto(1)} />
            </div>
          }
        </div>
      </div> :
        <>
          <div style={{ background: 'rgba(0,0,0,0.7)', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Cropper
              src={base64?.url}
              ref={cropperRef}
              zoomOnWheel={false}
              viewMode={1}
              center={false}
              toggleDragModeOnDblclick={false}
              aspectRatio={1} // 固定为1:1  可以自己设置比例, 默认情况为自由比例
              guides={false}
            />
          </div>
          <div style={{ display: 'flex', position: 'absolute', bottom: 0, width: '100%' }}>
            <Button style={{ flex: 1, borderRadius: 0, border: 0 }} onClick={() => setBase64({})}>取消</Button>
            <Button style={{ flex: 2, borderRadius: 0, border: 0 }} color='primary' onClick={() => handleSubmit()}>提交</Button>
          </div>
        </>
      }
    </>
  )
}

export default FaceAdd
