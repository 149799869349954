import { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom"
import { Toast, List, Input, Button, Collapse, Card, Radio } from 'antd-mobile-v5';
import { Fmoney, Trim, getQueryString } from '../../utils/utils'
import Request from '../../utils/request'
import Path from '../../utils/path'

const Invoicing = ({ location }) => {
  const { state } = location
  const billArr = state?.billArr
  let history = useHistory()
  // 业主token
  const token = getQueryString('token')
  document.title = '开具发票'

  const [emailValue, setEmailValue] = useState('')
  const [radioValue, setRadioValue] = useState('0')
  const [taxNumber, setTaxNumber] = useState('')
  const [lookUp, setLookUp] = useState('')

  const submitBill = () => {
    const res = /^\w+@[a-zA-Z0-9]+((\.[a-z0-9A-Z]{1,})+)$/
    if (!res.test(Trim(emailValue))) {
      Toast.show({ content: '请填写正确的发票接收邮箱!' })
      return
    }
    if (!Trim(lookUp)) {
      Toast.show({ content: '请填写发票抬头!' })
      return
    }
    if (radioValue == '0') {
      if (!Trim(taxNumber)) {
        Toast.show({ content: '请填写税号!' })
        return
      }
    }
    let params = {
      billType: '增值税电子发票',
      billTitle: lookUp,
      email: emailValue,
      snList: billArr.map(item => item.sn),
      titleType: radioValue,
      orderContent: '物业费',
    }
    if (radioValue == '0') {
      params.customerTaxCode = taxNumber
    }
    Request.post(Path.createBillPrintQywx, params, { headers: { authorization: 'Bearer ' + token } }).then(v => {
      if (v.success) {
        Toast.show({
          content: '发送成功, 请到邮箱查看!',
          maskClickable: false,
          afterClose: () => {
            history.push(`/Bill${window.location.search}`)
          }
        })
      }
    })
  }
  const titleHtml = () => {
    let amount = 0
    if (Array.isArray(billArr)) {
      amount = billArr.reduce((pre, cur) => pre + Number(cur.amount), 0)
    }
    return <div>开票金额：￥{Fmoney(amount)}</div>
  }
  return (
    <div style={{ margin: '10px 5px' }}>
      <Card style={{ padding: '0' }}>
        <Collapse className='collapseNoneBorder' style={{paddingRight: '12px'}}>
          <Collapse.Panel key='1' title={titleHtml()}>
            {Array.isArray(billArr) && billArr.map((item, index) => (
              <div key={index} style={{ marginTop: '10px', fontSize: '16px' }}>发票{index + 1}: ￥{Fmoney(item.amount)}</div>
            ))}
          </Collapse.Panel>
        </Collapse>
      </Card>
      <Card className='myCard' style={{ marginTop: '10px', padding: '0' }}>
        <List style={{ border: 0 }} className='listV5'>
          <List.Item extra='增值税电子普通发票'>
            <div style={{ color: '#999' }}>发票类型</div>
          </List.Item>
          <List.Item extra={<Radio.Group
            value={radioValue}
            onChange={(val) => setRadioValue(val)}
          >
            <Radio style={{ marginRight: '15px' }} value='1'>个人</Radio>
            <Radio value='0'>企业</Radio>
          </Radio.Group>}>
            <div style={{ color: '#999' }}>抬头类型</div>
          </List.Item>
          <List.Item extra={
            <Input className='rightInput' value={lookUp} onChange={value => setLookUp(value)} placeholder={radioValue== 0 ? '请填写企业名称': '请输入个人姓名'} clearable />}>
            <div style={{ color: '#999' }}>发票抬头</div>
          </List.Item>
          {radioValue == '0' && <List.Item extra={
            <Input className='rightInput' value={taxNumber} onChange={value => setTaxNumber(value)} placeholder='纳税人识别号' clearable />}>
            <div style={{ color: '#999' }}>税号</div>
          </List.Item>}
        </List>
      </Card>
      <Card style={{ marginTop: '10px', padding: '0' }} className='myCard'>
        <List style={{ border: 0 }} className='collapseNoneBorder'>
          <List.Item extra={<Input style={{ paddingRight: '12px', width: 'auto' }} className='rightInput' value={emailValue} onChange={value => setEmailValue(value)} placeholder='请填写接收发票邮箱' clearable />}>
            <div style={{ color: '#999' }}>接收邮箱</div>
          </List.Item>
        </List>
      </Card>
      <Button size='large' style={{ marginTop: '40px' }} color='primary' block onClick={() => submitBill()}>提交</Button>
    </div>
  )
}

export default Invoicing
