import { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom"
import { Card, DatePicker, ProgressCircle, Button, Toast } from 'antd-mobile-v5'
import { DownOutline } from 'antd-mobile-icons'
import { getQueryString } from '../../../utils/utils'
import Request from '../../../utils/request'
import Path from '../../../utils/path'
import moment from 'moment'

const PayStatis = () => {
  let history = useHistory()
  // 管家token
  const token = getQueryString('employeetoken')

  document.title = '物业缴费'
  const [dateValue, setDateValue] = useState(moment().format('YYYY-MM'))
  const [data, setData] = useState({})
  useEffect(() => {
    getData()
  }, [dateValue])

  const getData = () => {
    Request.get(Path.getTotalMonthTask, { params: { chargeMonth: dateValue }, headers: { authorization: 'Bearer ' + token } }).then(v => {
      if(v.success && v.data) {
        setData(v.data)
      }
    })
  }

  return (
    <div style={{ height: '100vh', padding: '15px', overflowY: 'auto' }}>
      <Card title='我的任务' extra={<span onClick={() => DatePicker.prompt({
        precision: 'month',
        value: moment(dateValue).toDate(),
        onConfirm: (dateTime) => {
          setDateValue(moment(dateTime).format('YYYY-MM'))
        }
      })}>{dateValue} <DownOutline fontSize={12} /></span>}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '15px', alignSelf: 'flex-start' }}>
                <div style={{ width: '2px', background: '#ff9500', height: '12px', marginRight: '5px' }}></div>
                <div>任务进度</div>
              </div>
              <ProgressCircle percent={data?.taskPercentage || 0} style={{ '--track-width': '8px', '--size': '90px' }}>
                {data?.taskPercentage || 0}
              </ProgressCircle>
            </div>
            <div style={{ flex: 1 }}>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                <div style={{ width: '2px', background: '#ff9500', height: '12px', marginRight: '5px' }}></div>
                <div>收款金额</div>
              </div>
              <div style={{ marginBottom: '20px', fontSize: '18px' }}>{data?.chargePaid || 0}<span style={{ fontSize: '12px', marginLeft: '5px' }}>元</span></div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                <div style={{ width: '2px', background: '#ff9500', height: '12px', marginRight: '5px' }}></div>
                <div>收款数量</div>
              </div>
              <div style={{ fontSize: '18px' }}>{data?.receiptsCount || 0}<span style={{ fontSize: '12px', marginLeft: '5px' }}>笔</span></div>
            </div>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginTop: '50px' }}>
            <div style={{ flex: 1, display: 'flex', justifyContent: 'space-around' }}>
              <div style={{textAlign: 'center' }}>
                <div style={{ fontSize: '16px', marginBottom: '10px', color: '#ff8300' }}>{data?.housePaid || 0}</div>
                <div>已缴户数</div>
              </div>
              <div style={{ textAlign: 'center' }}>
                <div style={{ fontSize: '16px', marginBottom: '10px', color: '#ff0000' }}>{data?.houseUnpaid || 0}</div>
                <div>未缴户数</div>
              </div>
            </div>
            <div style={{flex: 1, textAlign: 'center'}}>
              <Button color='primary' onClick={() => history.push(`/feeCall${window.location.search}`)}>费用催缴</Button>
            </div>
          </div>
        </div>
      </Card>
    </div>
  )
}

export default PayStatis
