import { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom"
import { Toast, Divider } from 'antd-mobile-v5'
import { getQueryString } from '../../../utils/utils'
import Request from '../../../utils/request'
import Path from '../../../utils/path'

const AppIndex = () => {
  let history = useHistory()
  // 管家token
  const token = getQueryString('employeetoken')

  document.title = '诚信行'

  const [flag, setFlag] = useState(false)
  useEffect(() => {
    if (token) {
      Request.get(Path.getEmpRole, { headers: { authorization: 'Bearer ' + token } }).then(v => {
        setFlag(true)
        if (v.success && v.data) {
          if (v.data == 1) {   // 管家
            history.push(`/employee${window.location.search}`)
          } else if (v.data == 2) {  // 其他
            history.push(`/manage${window.location.search}`)
          } else {
            Toast.show('员工已离职!')
          }
        }
      })
    } else {
      Toast.show('员工信息获取失败,请您重试!')
    }
  }, [])


  return (
    <div style={{ padding: '15px' }}>
      {flag && <Divider style={{ borderStyle: 'dashed' }}>
        您已离职或还没有绑定项目、角色
      </Divider>}
    </div>
  )
}

export default AppIndex
