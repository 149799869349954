import { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom"
import { SearchBar, Picker, Checkbox, Image, Grid } from 'antd-mobile-v5'
import { DownOutline } from 'antd-mobile-icons'
import { Trim } from '../../../utils/utils'
import Request from '../../../utils/request'
import Path from '../../../utils/path'
import HouseIcon from '../../../images/houseIcon.png'

const EmployeeHouse = ({ location }) => {
  const stateData = location.state
  let history = useHistory()

  document.title = '房屋信息'

  const [houseName, setHouseName] = useState('')
  const [status, setStatus] = useState(0)
  // const [nodeData, setNodeData] = useState([])
  const [building, setBuilding] = useState({ label: '', value: '' })
  const [unitValue, setUnitValue] = useState({ label: '', value: '' })
  const [data, setData] = useState([])

  useEffect(() => {
    let buildId = stateData?.buildList
    let params = {
      communityId: stateData?.communityId,
      name: houseName,
      status
    }
    if (building.value) {
      buildId = [building.value]
    }
    if (unitValue.value) {
      params.unitId = unitValue.value
    }
    params.buildId = buildId
    Request.post(Path.getTotalNodeInfo, params).then(v => {
      if (v.success && Array.isArray(v.data)) {
        setData(v.data)
      }
    })
  }, [houseName, status, unitValue])

  const getNodeInfo = async (nodeId, nodeType) => {
    let d = []
    await Request.get(Path.getNodeTypeAllData, { params: { nodeType, parentIds: nodeId } }).then(v => {
      if (v.success && Array.isArray(v.data)) {
        let allData = v.data
        if (Array.isArray(stateData.buildList) && stateData.buildList.length > 0 && nodeType == 3) {
          allData = v.data.filter(item => stateData.buildList.includes(item.id))
        }
        d = allData.map(item => ({ value: item.id, label: item.name }))
        d.push({ value: 0, label: '全部' })
      }
    })
    return d
  }

  const pickerHouse = async (type) => {
    const arrData = await getNodeInfo(type == 3 ? stateData?.communityId : building.value, type)
    Picker.prompt({
      columns: [arrData],
    }).then((value) => {
      const valueLabel = arrData.find(item => item.value == value)
      if (valueLabel) {
        if (type == 3) {  // 楼栋
          setBuilding(valueLabel)
          setUnitValue({ label: '', value: '' })
        } else if (type == 4) {
          setUnitValue(valueLabel)
        }
      }
    })
  }

  const clickHouse = item => {
    if (item.status == 2) {  // 已入住
      const houseInfo = item.buildingName + item.unitName + item.floorName + item.name
      history.push(`/employeeHouseDetail${window.location.search}`, { houseInfo, houseId: item.id })
    }
  }
  const getCss = status => {
    let css = {
      border: '1px solid #ddd',
      textAlign: 'center',
      height: '40px',
      lineHeight: '40px',
      borderRadius: '5px'
    }
    if (status == 2) {
      css.background = '#97D2FF'
      css.borderColor = '#97D2FF'
    }
    return css
  }
  return (
    <div style={{ padding: '10px 15px', background: '#fff' }}>
      <SearchBar placeholder='请输入房号, 如: 101' onSearch={val => setHouseName(Trim(val))} onClear={() => setHouseName('')} />
      <div style={{ display: 'flex', marginTop: '20px', alignItems: 'center', marginBottom: '30px' }}>
        <div onClick={() => pickerHouse(3)}>{building.label || '楼栋'} <DownOutline /></div>
        <div style={{ marginLeft: '20px' }} onClick={() => pickerHouse(4)}>{unitValue.label || '单元'} <DownOutline /></div>
        <div style={{ flex: 1, textAlign: 'right' }}><Checkbox checked={!!status} onChange={value => setStatus(value ? 1 : 0)} style={{
          '--icon-size': '18px',
          '--font-size': '14px',
        }}>只看未绑定</Checkbox></div>
      </div>
      <div>
        {data.length > 0 && data.map(item => {
          return (
            (Array.isArray(item.houseList) && item.houseList.length > 0) && <div key={item.unitId}>
              <div style={{ marginBottom: '15px', display: 'flex', alignItems: 'center' }}>
                <Image src={HouseIcon} height='22px' width='22px' />
                <span style={{ marginLeft: '5px' }}>{item.name}</span>
              </div>
              <Grid columns={4} gap={8} style={{ marginBottom: '25px' }}>
                {item.houseList.map((v) => {
                  return (
                    <Grid.Item key={v.id} onClick={() => clickHouse(v)}>
                      <div style={getCss(v.status)}>{v.name}</div>
                    </Grid.Item>
                  )
                })}
              </Grid>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default EmployeeHouse
