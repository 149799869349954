import { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom"
import { List, Toast, Picker } from 'antd-mobile';
import { TextArea, Card, ImageUploader, Space, Button, Toast as Toastv5 } from 'antd-mobile-v5';
import { getQueryString, Trim } from '../../../utils/utils'
import HouseModal from '../../../components/houseModal'
import Request from '../../../utils/request'
import Path from '../../../utils/path'

const Item = List.Item;

const ValetRepair = () => {
  let history = useHistory()
  // 管家token
  const token = getQueryString('employeetoken')
  // appid
  const appid = getQueryString('appid')

  document.title = '代客报修'

  const [communitys, setCommunitys] = useState({})
  const [showModal, setShowModal] = useState(false)
  const [onlyHouse, setOnlyHouse] = useState({ houseInfo: {} })
  const [fileList, setFileList] = useState([])
  const [textareaValue, setTextareaValue] = useState('')
  const [workType, setWorkType] = useState([])
  const [workValue, setWorkValue] = useState('')
  const [taker, setTaker] = useState([])
  const [takeValue, setTakeValue] = useState('')

  const [users, setUsers] = useState([])
  const [userId, setUserId] = useState('')

  useEffect(() => {
    Request.get(Path.getCommunityIds, { params: { projectType: 1 }, headers: { authorization: 'Bearer ' + token } }).then(v => {
      if (v.success && Array.isArray(v.data)) {
        setCommunitys(v.data[0])
        setOnlyHouse({ houseInfo: { ...onlyHouse.houseInfo, communityId: v.data[0]?.id } })
      }
    })
    Request.get(Path.workstateGetDictList, { params: { type: 2001 } }).then(v => {
      if (v.success && Array.isArray(v.data)) {
        const d = v.data.map(item => ({ value: item.code + '', label: item.name }))
        setWorkType(d)
      }
    })
  }, [])

  useEffect(() => {
    if (communitys?.id) {
      Request.get(Path.getCommunityByEmployee, { params: { communityId: communitys.id } }).then(v => {
        if (v.success && Array.isArray(v.data)) {
          const d = v.data.map(item => ({ phone: item.phone, value: item?.id + '', label: item?.name }))
          setTaker(d)
        }
      })
    }
  }, [communitys])

  const selectHouse = () => {
    if (communitys?.id) {
      setShowModal(true)
    } else {
      Toast.info('没有找到管家的小区!', 2)
    }
  }

  const submitHouse = value => {
    setShowModal(false)
    setOnlyHouse(value)
    // 获取房间用户
    getHouseUsers(value?.houseInfo?.houseId);
  }

  const getHouseUsers = houseId => {
    Request.get(Path.getHouseOwnerInfo, { params: { houseId } }).then(v => {
      if (v.success && Array.isArray(v.data)) {
        const d = v.data.map(item => ({ phone: item?.userInfo?.phone, value: item?.userInfo?.id + '', label: item?.userInfo?.name }))
        setUsers(d)
      }
    })
  }
  const bindHouse = async () => {
    if (userId && workValue && takeValue && Trim(textareaValue)) {
      let transactionId = ''
      if (fileList.length > 0) {
        const params = {
          fileUrls: fileList.map(v => v.url),
          type: 1,
        }
        await Request.post(Path.addEnclosure, params).then(v => {
          if (v.success) {
            transactionId = v.data
          }
        })
      }
      const nextUser = taker.find(v => takeValue == v.value)
      const user = users.find(v => userId == v.value)
      const params = {
        appId: appid,
        communityId: communitys?.id,
        content: Trim(textareaValue),
        houseId: onlyHouse?.houseInfo?.houseId,
        nextUserId: takeValue,
        nextUserName: nextUser.label,
        nextUserPhone: nextUser.phone,
        reportUserName: user.label,
        reportUserPhone: user.phone,
        systematicsCode: workValue,
        transactionId,
        userId: userId,
      }
      Request.post(Path.createByAdmin, params, { headers: { authorization: 'Bearer ' + token } }).then(v => {
        if (v.success) {
          Toast.info('创建成功!', 1, () => {
            history.push(`/workOrder${window.location.search}`)
          })
        }
      })
    } else {
      Toast.info('还有信息没有填完!', 2)
    }
  }
  const mockUpload = async file => {
    let formData = new FormData()
    formData.append('files', file)
    let fileUrl = {}
    await Request.post(Path.upload, formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(v => {
      if (v.success && v.data.length > 0) {
        fileUrl = { url: v.data[0] }
        setFileList(val => [...val, { url: v.data[0] }])
      }
    })
    Toastv5.clear()
    return fileUrl
  }
  const LimitCount = () => {
    return (
      <ImageUploader
        value={fileList}
        onChange={setFileList}
        upload={mockUpload}
        maxCount={6}
        showUpload={fileList.length < 6}
        onCountExceed={exceed => {
          Toast.show(`最多选择 6 张图片，你多选了 ${exceed} 张`)
        }}
      />
    )
  }

  return (
    <div style={{ height: '100vh', padding: '15px', overflowY: 'auto' }}>
      <List className={['infoList', 'ownerList']}>
        <Item extra={communitys?.name}>小区</Item>
        <Item arrow="horizontal" extra={onlyHouse?.houseInfo.buildingName ? onlyHouse?.houseInfo?.buildingName + onlyHouse?.houseInfo?.unitName + onlyHouse?.houseInfo?.floorName + onlyHouse?.houseInfo?.name : <span style={{ color: '#999999' }}>请选择房屋</span>}
          onClick={() => selectHouse()}>代办房屋</Item>
        <Picker data={users} cols={1} value={[userId]} onChange={v => setUserId(v[0])}>
          <Item arrow="horizontal">代办用户</Item>
        </Picker>
        <Picker data={workType} cols={1} value={[workValue]} onChange={v => setWorkValue(v[0])}>
          <Item arrow="horizontal">类型</Item>
        </Picker>
        <Picker data={taker} cols={1} value={[takeValue]} onChange={v => setTakeValue(v[0])}>
          <Item arrow="horizontal">接单人</Item>
        </Picker>
      </List>
      <Card title='报修内容' style={{ marginTop: '20px' }}>
        <TextArea
          placeholder='请输入报修内容'
          showCount
          maxLength={500}
          rows={4}
          style={{ '--font-size': '14px' }}
          value={textareaValue}
          onChange={val => setTextareaValue(val)}
        />
      </Card>
      <Card title={<div>上传照片 <span style={{ color: '#999' }}>(最多上传6张照片)</span></div>} style={{ marginTop: '20px' }}>
        <Space direction='vertical'>
          <LimitCount />
        </Space>
      </Card>
      <div style={{ display: 'flex', margin: '30px 15px 0' }}>
        <Button style={{ flex: 1, borderRadius: 0, border: 0 }} onClick={() => history.push(`/workOrder${window.location.search}&urlType=valet`)}>代报历史</Button>
        <Button style={{ flex: 2, borderRadius: 0, border: 0 }} onClick={() => bindHouse()} color='primary'>提交</Button>
      </div>
      {showModal && <HouseModal onlyHouse={onlyHouse} showModal={showModal} onClose={() => setShowModal(false)} onSubmit={value => submitHouse(value)} />}
    </div>
  )
}

export default ValetRepair
