import { useEffect, useState } from 'react';
import { Button, InputItem, List, Toast } from 'antd-mobile';
import Request from '../../utils/request'
import { getQueryString } from '../../utils/utils'
import Path from '../../utils/path'
import { Trim } from '../../utils/utils'

const Login = props => {
  const appId = getQueryString('appId')
  const headpath = getQueryString('headimgurl')
  const nick = getQueryString('nickname')
  const wxUnionId = getQueryString('wxUnionId')

  const { location: { state } } = props
  const [phone, setPhone] = useState('')
  const [code, setCode] = useState('') // 验证码
  const [num, setNum] = useState(0) // 倒计时
  const [isSend, setIsSend] = useState(false) // 是否发送验证码
  document.title = '请验证手机号'

  useEffect(() => {
    let timer = 0;
    if (isSend && num != 0) {
      timer = setInterval(() => {
        // 这时候的num由于闭包的原因，一直是0，所以这里不能用setNum(num-1)
        setNum(n => {
          if (n == 1) {
            setIsSend(false)
            clearInterval(timer)
          }
          return n - 1
        });
      }, 1000)
    }
    return () => {
      // 组件销毁时，清除定时器
      clearInterval(timer)
    };
  }, [isSend])

  const getMcode = e => {
    if(num == 0) {
      if (phone && phone.replace(/\s+/g,"").length == 11) {
        setNum(60)
        Request.post(Path.sendMCode, { phone: phone.replace(/\s+/g,"") })
        setIsSend(true)
      }
    }
  }
  const submitPhone = () => {
    if(phone && code) {
      const params = {
        mcode: code,
        phone: phone.replace(/\s+/g,""),
        authId: wxUnionId,
        type: 4,
        headpath,
        nick,
      }
      Request.post(Path.authBindAndLogin, params, { headers: { 'app-id': appId, 'X-Real-IP': 0 } }).then(v => {
        if (v.success && v.data) {
          const dd = v.data
          localStorage.setItem("userInfo", JSON.stringify({token: dd?.token, phone: dd.userInfo.phone, name:dd.userInfo.name }))
          window.location.href = state?.url + '&token=' + dd?.token
        } else {
          Toast.info(v.errorMsg || '绑定失败')
        }
      })
    }
  }
  return (
    <div>
      <div style={{ margin: '15px', background: '#fff', borderRadius: '6px' }}>
        <List className={['infoList', 'ownerList']}>
          <InputItem
            placeholder="请输入您的手机号"
            onChange={v => setPhone(Trim(v))}
            value={phone}
            type="phone"
          >手机号</InputItem>
          <InputItem
            placeholder="请输入验证码"
            onChange={v => setCode(Trim(v))}
            value={code}
            extra={num == 0 ? <span style={{ fontSize: '14px', color: '#00AAFF'}}>获取验证码</span> : <span>{num}秒</span>}
            onExtraClick={e=>getMcode(e)}
          >验证码</InputItem>
        </List>
      </div>
      <div style={{ margin: '15px' }}>
        <Button type="primary" style={{ marginTop: '30px', fontSize: '14px', background: '#00AAFF' }} onClick={() => submitPhone()}>绑定手机并登录</Button>
        <div style={{ color: '#666666', fontSize: '12px', marginTop: '15px', textAlign: 'center' }}>请先登陆后再进行其他操作</div>
      </div>
    </div>
  )
}

export default Login
