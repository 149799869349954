import axios from 'axios'
import { Toast } from 'antd-mobile'

//使用create方法创建axios实例
const Request = axios.create({
  timeout: 50000, // 请求超时时间
  method: 'post',
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
  }
})
// 添加请求拦截器
Request.interceptors.request.use(async config => {
  Toast.loading('Loading...', 0)
  return config
})
// 添加响应拦截器
Request.interceptors.response.use(async response => {
  Toast.hide()
  let result = { success: false };
  const obj = await response.data;
  if (obj.success) {
    result = obj;
  } else {
    if (obj?.errorMsg) {
      Toast.info(obj.errorMsg, 3)
    } else {
      Toast.info('服务异常', 3)
    }
  }
  return result;
})
export default Request