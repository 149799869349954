import { Route, Switch } from 'react-router-dom';
import Seneschal from './pages/Seneschal/OwnerInfo'
import AppIndex from './pages/Seneschal/AppIndex'
import Employee from './pages/Seneschal/AppIndex/employee'
import Manage from './pages/Seneschal/AppIndex/manage'
import ManageProject from './pages/Seneschal/AppIndex/ManageProject'
import HouseReview from './pages/Seneschal/HouseReview'
import ValetRepair from './pages/Seneschal/ValetRepair'
import PayStatis from './pages/Seneschal/PayStatis'
import FeeCall from './pages/Seneschal/PayStatis/feeCall'
import Article from './pages/Seneschal/Article'
import AddArticle from './pages/Seneschal/Article/addArticle'
import DetailArticle from './pages/Seneschal/Article/detailArticle'
import WorkOrder from './pages/WorkOrder'
import OrderDetail from './pages/WorkOrder/orderDetail'
import AuthRoute from './pages/AuthRoute'
import BindHouse from './pages/BindHouse'
import ReportForRepair from './pages/ReportForRepair'
import HouseInfo from './pages/HouseInfo'
import HouseDetail from './pages/HouseDetail'
import Bill from './pages/Bill'
import CompleBill from './pages/Bill/compleBill'
import BillInfo from './pages/Bill/billInfo'
import Invoicing from './pages/Bill/invoicing'
import FaceAdd from './pages/FaceAdd'
import PropertyPay from './pages/PropertyPay'
import PaymentRecord from './pages/PaymentRecord'
import PayMoney from './pages/PayMoney'
import Answer from './pages/Answer'
import Barbie from './pages/Answer/barbie'
import Login from './pages/Login'
import EmployeeHouse from './pages/Seneschal/EmployeeHouse'
import EmployeeHouseDetail from './pages/Seneschal/EmployeeHouse/houseDetail'
import YunZhiJia from './pages/Seneschal/YunZhiJia'
import YLogin from './pages/Seneschal/YunZhiJia/Login'
import FrontPage from './pages/Seneschal/YunZhiJia/FrontPage'
import Business from './pages/Business'

import './App.css';

const App = () => {
  return (
    <Switch>
      <AuthRoute path='/BindHouse' component={BindHouse}></AuthRoute>
      <AuthRoute path='/PropertyPay' component={PropertyPay}></AuthRoute>
      <AuthRoute path='/ReportForRepair' component={ReportForRepair}></AuthRoute>
      <Route exact path='/Login' component={Login} />
      <Route exact path='/HouseInfo' component={HouseInfo} />
      <Route exact path='/HouseDetail' component={HouseDetail} />
      <Route exact path='/FaceAdd' component={FaceAdd} />
      <Route exact path='/PaymentRecord' component={PaymentRecord} />
      <Route exact path='/PayMoney' component={PayMoney} />
      <Route exact path='/Bill' component={Bill} />
      <Route exact path='/CompleBill' component={CompleBill} />
      <Route exact path='/BillInfo' component={BillInfo} />
      <Route exact path='/Invoicing' component={Invoicing} />
      <Route exact path='/seneschal' component={Seneschal} />
      <Route exact path='/appIndex' component={AppIndex} />
      <Route exact path='/employee' component={Employee} />
      <Route exact path='/manage' component={Manage} />
      <Route exact path='/manageProject' component={ManageProject} />
      <Route exact path='/houseReview' component={HouseReview} />
      <Route exact path='/valetRepair' component={ValetRepair} />
      <Route exact path='/workOrder' component={WorkOrder} />
      <Route exact path='/orderDetail' component={OrderDetail} />
      <Route exact path='/payStatis' component={PayStatis} />
      <Route exact path='/feeCall' component={FeeCall} />
      <Route exact path='/article' component={Article} />
      <Route exact path='/addArticle' component={AddArticle} />
      <Route exact path='/detailArticle' component={DetailArticle} />
      <Route exact path='/employeePropertyPay' component={PropertyPay} />
      <Route exact path='/wjx' component={Answer} />
      <Route exact path='/barbie' component={Barbie} />
      <Route exact path='/employeeHouse' component={EmployeeHouse} />
      <Route exact path='/employeeHouseDetail' component={EmployeeHouseDetail} />
      <Route exact path='/yunZhiJia' component={YunZhiJia} />
      <Route exact path='/frontPage' component={FrontPage} />
      <Route exact path='/yLogin' component={YLogin} />
      <Route exact path='/Business' component={Business} />
    </Switch>
  );
}

export default App;
