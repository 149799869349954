import { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom"
import { Card, Skeleton } from 'antd-mobile-v5'
import { UserCircleOutline } from 'antd-mobile-icons'
import { getQueryString } from '../../../utils/utils'
import Request from '../../../utils/request'
import Path from '../../../utils/path'

const Employee = () => {
  let history = useHistory()

  // 管家token
  const token = getQueryString('employeetoken')

  document.title = '诚信行'

  const [data, setData] = useState()
  const [myData, setMyData] = useState({})

  useEffect(() => {
    Request.get(Path.getTotalManagerNode, { headers: { authorization: 'Bearer ' + token } }).then(v => {
      if (v.success && v.data) {
        setData(v.data)
      }
    })
    
    Request.get(Path.getTotalHouseManager, { headers: { authorization: 'Bearer ' + token } }).then(v => {
      if (v.success && v.data) {
        setMyData(v.data)
      }
    })
  }, [])

  return (
    <div style={{ margin: '10px 15px' }}>
      <Card title={<div style={{display: 'flex', alignItems: 'center'}}><UserCircleOutline style={{color: '#FF9500'}} fontSize={22} /><span style={{marginLeft: '5px'}}>管理项目</span></div>}>
        <div style={{ display: 'flex', marginBottom: '20px' }} onClick={() => history.push(`/manageProject${window.location.search}`, {count: data?.projectCount})}>
          <div style={{ flex: 1, textAlign: 'center' }}>
            <div style={{ fontSize: '16px', marginBottom: '10px' }}>{data?.projectCount}</div>
            <div>总项目</div>
          </div>
          <div style={{ flex: 1, textAlign: 'center' }}>
            <div style={{ fontSize: '16px', marginBottom: '10px' }}>{data?.houseCount}</div>
            <div>房屋总数</div>
          </div>
        </div>
        
        <div onClick={() => history.push(`/manageProject${window.location.search}`, {count: data?.projectCount})} style={{ paddingTop: '25px', textAlign: 'center' }}>
          {`查看详情 >`}
        </div>
      </Card>
    
      <Card title='团队数据总览' style={{ marginTop: '15px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '5px' }}>
          <div style={{ flex: 1, textAlign: 'center' }}>
            <div style={{ fontSize: '16px', marginBottom: '10px' }}>{myData?.approveHouse || 0}</div>
            <div>认证房屋</div>
          </div>
          <div style={{ flex: 1, textAlign: 'center' }}>
            <div style={{ fontSize: '16px', marginBottom: '10px' }}>{myData?.approveUser || 0}</div>
            <div>认证用户</div>
          </div>
          <div style={{ flex: 1, textAlign: 'center' }}>
            <div style={{ fontSize: '16px', marginBottom: '10px' }}>{myData?.houseRate || '0%'}</div>
            <div>平均房屋认证率</div>
          </div>
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '35px' }}>
          <div style={{ flex: 1, textAlign: 'center' }}>
            <div style={{ fontSize: '16px', marginBottom: '10px' }}>{myData?.customer || 0}</div>
            <div>总客户数</div>
          </div>
          <div style={{ flex: 1, textAlign: 'center' }}>
            <div style={{ fontSize: '16px', marginBottom: '10px' }}>{myData?.groupChat || 0}</div>
            <div>群聊数</div>
          </div>
          <div style={{ flex: 1, textAlign: 'center' }}>
            <div style={{ fontSize: '16px', marginBottom: '10px' }}>{myData?.customerRate || '0%'}</div>
            <div>平均客户添加率</div>
          </div>
        </div>
      </Card>
    </div>
  )
}

export default Employee
