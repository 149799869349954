
// 房屋审核

import { useEffect, useState } from 'react';
import { Toast, Modal } from 'antd-mobile';
import Request from '../../../utils/request'
import Path from '../../../utils/path'
import { getQueryString } from '../../../utils/utils'
import DefaultHearder from '../../../images/defaultHearder.png'
import NoData from '../../../images/noData.png'
import Style from './index.module.css'

const alert = Modal.alert;

const HouseReview = () => {
  const [dataList, setDataList] = useState([])

  document.title = '房屋审核'
  // 管家token
  const token = getQueryString('employeetoken')
  // 模板id
  const tmpId = getQueryString('tmpId')

  useEffect(() => {
    getListData()
  }, [])

  const getListData = () => {
    Request.get(Path.getEmployeeReviewList, { headers: { authorization: 'Bearer ' + token } }).then(res => {
      if (res.success && Array.isArray(res.data)) {
        setDataList(res.data)
      }
    })
  }

  const submitButton = (id, status) => {
    alert('提示', `确定${status == 1?'通过':'拒绝'}?`, [
      { text: '取消', onPress: () => console.log('cancel') },
      { text: '确定', onPress: () => {
        const params = {
          id,
          status,
          tmpId
        }
        Request.post(Path.approveQywx, params, {headers: { authorization: 'Bearer ' + token } }).then(res => {
          if (res.success) {
            Toast.info('操作成功!',2)
            getListData()
          }
        })
      }},
    ])
  }

  const splitPhone = phone => {
    let result = ''
    if (phone && phone.length == 11) {
      result = phone.substring(0, 3)+"****"+phone.substr(phone.length-4)
    }
    return result
  }

  return (
    <div>
      <div style={{ margin: '15px' }}>
        {dataList.length > 0 ? dataList.map(item => <div className={Style.listDivStyle}>
          <div style={{ display: 'flex', marginBottom: '15px' }}>
            <img className={Style.hearderStyle} src={item?.userInfo?.headpath || DefaultHearder} />
            <div style={{fontSize: '12px', color: '#666'}}>
              <div style={{fontSize: '14px', color: '#333', display: 'flex', alignItems: 'center', marginBottom: '5px'}}>
                <span>{item?.userInfo?.name}</span>
                <div className={Style.buttonType}>{item?.type?.name}</div>
              </div>
              <div style={{marginBottom: '5px'}}>{item?.houseInfo?.communityName+item?.houseInfo?.buildingName+item?.houseInfo?.unitName+item?.houseInfo?.floorName+item?.houseInfo?.name}</div>
              <div>{splitPhone(item?.userInfo?.phone)}</div>
            </div>
          </div>

          <div style={{display: 'flex', alignItems: 'center'}}>
            <div className={Style.purchase} onClick={()=>submitButton(item.id, 2)}>
              <text>拒绝</text>
            </div>
            <div className={Style.recharge} onClick={()=>submitButton(item.id, 1)}>
              <text>通过</text>
            </div>
          </div>
        </div>):<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '150px'}}><img src={NoData} style={{width: '150px', height: '119px'}} /></div>}
      </div>
    </div>
  );
}

export default HouseReview;
