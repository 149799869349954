import { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom"
import { Divider, List } from 'antd-mobile-v5';
import { getQueryString, Fmoney } from '../../utils/utils'
import Request from '../../utils/request'
import Path from '../../utils/path'
import moment from 'moment'

const CompleBill = () => {
  let history = useHistory()
  // 业主token
  const token = getQueryString('token')

  document.title = '开票历史'

  const [data, setData] = useState([])
  const [flag, setFlag] = useState(false)

  useEffect(() => {
    Request.get(Path.getHistoricalOrder, { headers: { authorization: 'Bearer ' + token } }).then(res => {
      if (res.success && Array.isArray(res.data)) {
        setData(res.data)
      }
      setFlag(true)
    })
  }, [])

  return (
    <div>
      {data.length > 0 ?
        <List style={{fontSize: '14px'}}>
          {
            data.map(item => <div key={item.id}>
              <List.Item
                extra='已开票'
                title={item.houseName}
                description={item.payTime ? moment(item.payTime).format('YYYY-MM-DD HH:mm') : ''}
                onClick={() => history.push(`/BillInfo${window.location.search}`, { sn: item.sn })}
              >
                <div style={{ margin: '5px 0' }}>{Fmoney(item.amount)}</div>
              </List.Item>
            </div>)
          }
        </List> : flag ?
          <Divider style={{ borderStyle: 'dashed' }}>
            暂无已开票记录
          </Divider> : ''}
    </div>
  )
}

export default CompleBill
