import { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom"
import { List, Tag, Toast, Checkbox, Image, Grid } from 'antd-mobile-v5'
import { getQueryString, Trim } from '../../../utils/utils'
import Request from '../../../utils/request'
import Path from '../../../utils/path'
import HouseIcon from '../../../images/houseIcon.png'

const HouseDetail = ({ location }) => {
  const stateData = location.state

  // 管家token
  const token = getQueryString('employeetoken')

  document.title = '房屋信息'

  const [data, setData] = useState([])

  useEffect(() => {
    Request.get(Path.getUserList, { params: { houseId: stateData?.houseId }, headers: { authorization: 'Bearer ' + token } }).then(v => {
      if (v.success && Array.isArray(v.data) && v.data.length > 0) {
        const newData = v.data.filter(item=>item?.status?.code == 1)
        setData(newData)
      }
    })
  }, [])


  return (
    <div style={{height: '100vh', overflowX: 'hidden'}}>
      <div style={{ marginBottom: '5px', display: 'flex', alignItems: 'center', background: '#fff', padding: '10px 15px' }}>
        <Image src={HouseIcon} height='22px' width='22px' />
        <span style={{ marginLeft: '8px', fontSize: '16px' }}>{stateData?.houseInfo}</span>
      </div>
      <List style={{'--border-bottom': 0, '--border-top': 0}}>
        {Array.isArray(data) && data.map(item => (
          <List.Item
            key={item.id}
            prefix={
              <Image
                src={item?.userInfo?.headpath}
                style={{ borderRadius: 25 }}
                fit='cover'
                width={50}
                height={50}
              />
            }
            description={<div style={{fontSize: '12px', paddingBottom: '8px'}}>{`入住时间: ${item?.approveTime}`}</div>}
          >
            <div style={{fontSize: '14px', marginBottom: '10px', paddingTop: '8px'}}>
              <span>{item?.userInfo?.name}</span>
              <Tag style={{margin: '0 15px', padding: '2px'}} color='primary' fill='outline'>{item?.type?.name}</Tag>
              <span>{item?.userInfo?.phone}</span>
            </div>
          </List.Item>
        ))

        }
      </List>
    </div>
  )
}

export default HouseDetail
