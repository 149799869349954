import { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom"
import { TextArea, Card, ImageUploader, Space, Button, Toast } from 'antd-mobile-v5';
import { getQueryString, Trim } from '../../../utils/utils'
import Request from '../../../utils/request'
import Path from '../../../utils/path'

const AddArticle = ({location}) => {
  const data = location?.state
  let history = useHistory()
  // 管家token
  const token = getQueryString('employeetoken')
  const appId = getQueryString('appId')
  const communityId = getQueryString('communityId')

  document.title = '物业瞬间'

  const [fileList, setFileList] = useState([])
  const [textareaValue, setTextareaValue] = useState('')

  useEffect(()=>{
    if(data) {
      setTextareaValue(data?.content)
      if(Array.isArray(data?.pictureList) && data?.pictureList.length > 0) {
        const dd = data?.pictureList.map(item=>{
          return { url: item }
        })
        setFileList(dd)
      }
    }
  }, [])
  const onSubmit = async () => {
    if (textareaValue) {
      let params = {
        pictureList: fileList.map(v => v.url),
        content: textareaValue,
        projectId: communityId,
      }
      let url = 'addArticleByEmployee'
      if(data) {        // 编辑
        params.id = data?.id
        url = 'updateMoment'
      }
      Request.post(Path[url], params, { headers: { authorization: 'Bearer ' + token, 'app-id': appId } }).then(v => {
        if (v.success) {
          Toast.show({
            content: '成功!',
            duration: 1000,
            afterClose: () => {
              history.push(`/article${window.location.search}&type=1`)
            },
          })
        }
      })
    } else {
      Toast.show('还有信息没有填完!', 2)
    }
  }
  const mockUpload = async file => {
    let formData = new FormData()
    formData.append('files', file)
    let fileUrl = {}
    await Request.post(Path.upload, formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(v => {
      if (v.success && v.data.length > 0) {
        fileUrl = { url: v.data[0] }
        setFileList(val => [...val, { url: v.data[0] }])
      }
    })
    Toast.clear()
    return fileUrl
  }
  const LimitCount = () => {
    return (
      <ImageUploader
        value={fileList}
        onChange={setFileList}
        upload={mockUpload}
        maxCount={6}
        showUpload={fileList.length < 6}
        onCountExceed={exceed => {
          Toast.show(`最多选择 6 张图片，你多选了 ${exceed} 张`)
        }}
      />
    )
  }

  return (
    <div style={{ height: '100vh', padding: '15px', overflowY: 'auto' }}>
      <Card title='瞬间内容'>
        <TextArea
          placeholder='请输入物业瞬间内容'
          showCount
          maxLength={500}
          rows={4}
          style={{ '--font-size': '14px' }}
          value={textareaValue}
          onChange={val => setTextareaValue(Trim(val))}
        />
      </Card>
      <Card title={<div>上传照片 <span style={{ color: '#999' }}>(最多上传6张照片)</span></div>} style={{ marginTop: '20px' }}>
        <Space direction='vertical'>
          <LimitCount />
        </Space>
      </Card>
      <div style={{ display: 'flex', margin: '30px 15px 0' }}>
        <Button style={{ flex: 1, borderRadius: 0, border: 0 }} onClick={() => onSubmit()} color='primary'>发布</Button>
        <Button style={{ flex: 1, borderRadius: 0, border: 0 }} onClick={() => history.push(`/article${window.location.search}&type=1`)}>瞬间列表</Button>
      </div>
    </div>
  )
}

export default AddArticle
