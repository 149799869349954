
// 获取url参数
export const getQueryString = name => {
  const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i")
  const r = window.location.search.substr(1).match(reg)
  if (r != null) return decodeURI(r[2])
  return null
}

// 去除左右空格
export const Trim = (str) => {
  return str.toString().replace(/(^\s*)|(\s*$)/g, "");
}

export const Fmoney = (s, n) => {
  /*
   * 参数说明：
   * s：要格式化的数字 (后端给的是分 这里要除100)
   * n：保留几位小数 默认2位
   * */
  s = s / 100
  n = n > 0 && n <= 20 ? n : 2;
  s = parseFloat((s + "").replace(/[^\d\.-]/g, "")).toFixed(n) + "";
  const l = s.split(".")[0].split("").reverse(),
    r = s.split(".")[1];
  let t = "";
  for (let i = 0; i < l.length; i++) {
    t += l[i] + ((i + 1) % 3 == 0 && (i + 1) != l.length ? "," : "");
  }
  return t.split("").reverse().join("") + "." + r;
}