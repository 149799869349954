import { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom"
import { List, Toast, Button, Modal } from 'antd-mobile';
import { Image, Space, ImageViewer } from 'antd-mobile-v5';
import { getQueryString } from '../../utils/utils'
import Request from '../../utils/request'
import Path from '../../utils/path'

const alert = Modal.alert;
const Item = List.Item;

const HouseDetail = ({location}) => {
  const {state} = location
  const data = state
  let history = useHistory()
  // 业主token
  const token = getQueryString('token')
  // 管家token
  const employeetoken = getQueryString('employeetoken')

  let userInfo = localStorage.getItem("userInfo")
  userInfo = JSON.parse(userInfo)

  document.title = '房屋详情'

  const [faceShow, setFaceShow] = useState(false)
  const [faceData, setFaceData] = useState([])

  useEffect(()=>{
    Request.get(Path.getFaceDevice, { params: { projectId: data?.houseInfo?.communityId } }).then(v => {
      if (v.success) {
        setFaceShow(v?.data)
      }
    })
  }, [])

  useEffect(()=>{
    if(faceShow) {
      Request.get(Path.selectWXFaceByHouseId, { params: { houseId: data?.houseInfo?.houseId }, headers: { authorization: 'Bearer ' + token } }).then(v => {
        if (v.success && Array.isArray(v.data)) {
          setFaceData(v.data)
        }
      })
    }
  }, [faceShow])
  const deleteHouse = () => {
    const params = {
      id: data.id
    }
    Request.post(Path.deleteMyHouse, params, { headers: { authorization: 'Bearer ' + token } }).then(v => {
      if (v.success) {
        Toast.info('成功!', 1, ()=>{
          history.push(`/HouseInfo${window.location.search}`)
        })
      }
    })
  }
  
  const openImage = (e, index) => {
    e.stopPropagation()
    ImageViewer.Multi.show({ images: faceData.filter(v=>v).map(d=>d?.faceUrl), defaultIndex: index })
  }
  
  return (
    <div style={{ height: '100vh' }}>
      <List className={['infoList', 'ownerList']} style={{ margin: '15px 15px 0 15px' }}>
        <Item extra={data?.houseInfo?.communityName}>小区</Item>
        <Item extra={data?.houseInfo?.buildingName + data?.houseInfo?.unitName + data?.houseInfo?.floorName + data?.houseInfo?.name}>楼栋/单元/房号</Item>
        {/* {
          faceShow && <Item arrow="horizontal" extra={faceData.length > 0 && <Space wrap>
            {faceData.map((item, index) => (
              item && <Image key={index} src={item?.faceUrl} style={{ borderRadius: 8 }} onClick={e => openImage(e, index)} />
            ))}
          </Space>} onClick={()=>history.push(`/FaceAdd${window.location.search}`, {data:faceData, houseId:data?.houseInfo?.houseId })}>人脸信息</Item>
        } */}
        <Item extra={data?.type?.name}>身份</Item>
        <Item extra={userInfo?.name}>姓名</Item>
        <Item extra={userInfo?.phone}>手机号</Item>
      </List>
      {
        data?.status?.code == 0 && <Button style={{ background: '#00AAFF', color: '#fff', margin: '30px 15px 0' }} onClick={() =>
          alert('提示', '确定取消审核?', [
            { text: '取消', onPress: () => console.log('cancel') },
            { text: '确定', onPress: () => deleteHouse() },
          ])
        }>取消审核</Button>
      }
      {
        (data?.status?.code == 1 && data?.type?.code != 1) && <Button style={{ background: '#00AAFF', color: '#fff', margin: '30px 15px 0' }} onClick={() =>
          alert('提示', '确定退出房屋?', [
            { text: '取消', onPress: () => console.log('cancel') },
            { text: '确定', onPress: () => deleteHouse() },
          ])
        }>退出房屋</Button>
      }
    </div>
  )
}

export default HouseDetail
