import { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom"
import { Card, Skeleton, Divider } from 'antd-mobile-v5'
import { getQueryString } from '../../../../utils/utils'
import Request from '../../../../utils/request'
import Path from '../../../../utils/path'

const ManageProject = ({ location }) => {
  const count = location.state?.count || 1
  let history = useHistory()

  // 管家token
  const token = getQueryString('employeetoken')

  document.title = '项目概况'

  const [data, setData] = useState([])
  const [flag, setFlag] = useState(false)

  useEffect(() => {
    Request.get(Path.getProjectGeneralize, { headers: { authorization: 'Bearer ' + token } }).then(v => {
      setFlag(true)
      if (v.success && Array.isArray(v.data)) {
        setData(v.data)
      }
    })
  }, [])

  return (
    <div style={{ padding: '5px 10px' }}>
      {flag ? data.length > 0 ?
        data.map(item => (
          <Card style={{ marginBottom: '15px' }} key={item.projectId} title={<div style={{ display: 'flex', alignItems: 'center', width: '88vw', fontSize: '14px' }}
            onClick={() => history.push(`/employeeHouse${window.location.search}`, { communityId: item.projectId })}>
            <span style={{ fontSize: '15px' }}>{item.projectName}</span>
            <span style={{ color: '#3B33FF', marginLeft: '20px' }}>{item.houseTotal || 0}户</span>
            <span style={{ flex: 1, textAlign: 'right', fontSize: '12px', color: '#999' }}>{`查看详情 >`}</span>
          </div>}>
            <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '5px' }}>
              <div style={{ flex: 1, textAlign: 'center' }}>
                <div style={{ marginBottom: '15px' }}>认证房屋</div>
                <div>{item?.approveHouse || 0}</div>
              </div>
              <div style={{ flex: 1, textAlign: 'center' }}>
                <div style={{ marginBottom: '15px' }}>认证人数</div>
                <div>{item?.approveUser || 0}</div>
              </div>
              <div style={{ flex: 1, textAlign: 'center', color: '#F7AA00' }}>
                <div style={{ marginBottom: '15px' }}>空闲房屋</div>
                <div>{item?.emptyHouse || 0}</div>
              </div>
              <div style={{ flex: 1, textAlign: 'center', color: '#0900E1' }}>
                <div style={{ marginBottom: '15px' }}>认证率</div>
                <div>{item?.houseRate || '0%'}</div>
              </div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '25px', marginBottom: '10px' }}>
              <div style={{ flex: 1, textAlign: 'center' }}>
                <div style={{ marginBottom: '15px' }}>客户总数</div>
                <div>{item?.customer || 0}</div>
              </div>
              <div style={{ flex: 1, textAlign: 'center' }}>
                <div style={{ marginBottom: '15px' }}>客户添加率</div>
                <div>{item?.customerRate || '0%'}</div>
              </div>
              <div style={{ flex: 1, textAlign: 'center' }}>
                {/* <div style={{ marginBottom: '15px' }}>物业收费率</div>
                <div>{item?.amountRate || '0%'}</div> */}
              </div>
              <div style={{ flex: 1, textAlign: 'center' }}></div>
            </div>
          </Card>
        ))
        : <Divider style={{ borderStyle: 'dashed' }}>
          暂无数据
        </Divider> :
        <div>
          {Array.from({ length: count }, (v, k) => (
            <div style={{ marginBottom: '40px' }} key={k}>
              <Skeleton.Title animated />
              <Skeleton.Paragraph lineCount={5} animated />
            </div>
          ))
          }
        </div>
      }
    </div>
  )
}

export default ManageProject
