import { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom"
import { Toast, List, Input, Button } from 'antd-mobile-v5';
import { Fmoney, Trim } from '../../utils/utils'
import Request from '../../utils/request'
import Path from '../../utils/path'
import moment from 'moment'

const BillInfo = ({ location }) => {
  const { state } = location
  let history = useHistory()

  document.title = '发票详情'

  const [data, setData] = useState({})
  const [inputValue, setInputValue] = useState('')

  useEffect(() => {
    Request.get(Path.getOrderPrintBySn, { params: { sn: state?.sn } }).then(res => {
      if (res.success && res.data) {
        setData(res.data)
        setInputValue(res.data?.email)
      }
    })
  }, [])

  const againBill = () => {
    const res = /^\w+@[a-zA-Z0-9]+((\.[a-z0-9A-Z]{1,})+)$/
    if(res.test(Trim(inputValue))) {
      const params = {
        email:Trim(inputValue),
        id: data?.id
      }
      Request.post(Path.sendOrderEmail, params).then(v => {
        if (v.success) {
          Toast.show({
            content: '发送成功, 请到邮箱查看!',
            maskClickable: false,
            afterClose: ()=>{
              history.push(`/CompleBill${window.location.search}`)
            }
          })
        }
      })
    } else {
      Toast.show({content: '请填写正确的发票接收邮箱!'})
    }
  }
  return (
    <div>
      <div style={{ margin: '15px 10px', fontSize: '16px' }}>发票详情</div>
      <List style={{ border: 0, padding: '0 10px' }} className='listV5'>
        <List.Item
          extra='增值税电子普通发票'
          title={<div style={{ fontSize: '16px' }}>发票类型</div>}
        />
        <List.Item
          extra={data.billTitle}
          title={<div style={{ fontSize: '16px' }}>发票抬头</div>}
        />
        {data?.titleType == 0 && <List.Item
          extra={data.customerTaxCode}
          title={<div style={{ fontSize: '16px' }}>税号</div>}
        />}
        <List.Item
          extra={data.amount ? Fmoney(data.amount) : ''}
          title={<div style={{ fontSize: '16px' }}>发票金额</div>}
        />
        <List.Item
          extra={data.gmtCreate? moment(data.gmtCreate).format('YYYY-MM-DD HH:mm'):''}
          title={<div style={{ fontSize: '16px' }}>提交时间</div>}
        />
      </List>
      <div style={{ margin: '15px 10px', fontSize: '16px' }}>接收方式</div>
      <List style={{ border: 0 }}>
        <List.Item prefix={<div style={{color: '#999'}}>电子邮箱</div>}>
          <Input value={inputValue} onChange={value=>setInputValue(value)} placeholder='请填写开票邮箱' clearable />
        </List.Item>
      </List>
      <div style={{marginTop: '40px', padding: '0 15px'}}>
        <Button size='large' color='primary' block onClick={()=>againBill()}>重新开票</Button>
      </div>
    </div>
  )
}

export default BillInfo
