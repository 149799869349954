import { useEffect } from 'react'
import { getQueryString } from '../../utils/utils'
import Request from '../../utils/request'
import Path from '../../utils/path'
import { Toast } from 'antd-mobile-v5'

const Business = () => {
  const link = window.location.search.lastIndexOf("link=");
  const s5 = window.location.search.slice(link+5);
  const agentid = getQueryString(agentid)
  document.title = ''
  useEffect(() => {
    Request.get(Path.qywxUrlGetDictList, { params: { type: agentid } }).then(v => {
      if (v.success && v.data.length > 0) {
        const url = v.data[0]?.code
        window.location.href= `${url}${s5}`
      } else {
        Toast.info('打开失败!')
      }
    })

   // window.location.href= `${link}&headimgurl=${headimgurl}&nickname=${nickname}&wxUnionId=${wxUnionId}`
  }, [])
  
  return (
    <div></div>
  )
}

export default Business
