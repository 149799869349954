import { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom"
import { InputItem, List, Picker, Toast, Button } from 'antd-mobile';
import { getQueryString, Trim } from '../../utils/utils'
import HouseModal from '../../components/houseModal'
import Request from '../../utils/request'
import Path from '../../utils/path'

const Item = List.Item;

const BindHouse = ({ location }) => {
  const { state } = location
  const typeFlag = state?.typeFlag
  let history = useHistory()
  // 业主token
  const token = getQueryString('token')
  // 管家id
  const employeeId = getQueryString('employeeId')
  // 模板id
  const tmpId = getQueryString('tmpId')

  let userInfo = localStorage.getItem("userInfo")
  userInfo = JSON.parse(userInfo)

  document.title = '绑定房屋'

  const [dictList, setDictList] = useState([])
  const [identity, setIdentity] = useState('')
  const [communitys, setCommunitys] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [onlyHouse, setOnlyHouse] = useState({ houseInfo: {} })
  const [userName, setUserName] = useState(userInfo?.name)
  const [employeetoken, setEmployeetoken] = useState(() => getQueryString('employeetoken')) // 从其他页面给过来的管家token

  useEffect(() => {
    if (employeeId) {
      // 获取管家token
      Request.get(Path.getEmployeeById, { params: { employeeId } }).then(v => {
        if (v.success) {
          setEmployeetoken(v.data?.token)
          getHouse(v.data?.token)
        }
      })
    } else if(employeetoken && employeetoken != 'null' && employeetoken != 'undefined') {
      getHouse(employeetoken)
    } else {
      getHouse()
    }
  }, [])

  const getHouse = tt => {
    // 判断业主是否有房屋
    Request.get(Path.getHouseList, { headers: { authorization: 'Bearer ' + token } }).then(v => {
      if (v.success && v?.data.length > 0) {
        // 有房屋跳转到房屋详情
        if (!typeFlag) {
          history.push(`/HouseInfo${window.location.search}&employeetoken=${tt}`)
          return
        }
      }
      otherInterface(tt)
    })
  }

  const otherInterface = tokenParams => {
    Request.get(Path.authGetDictList, { params: { type: '4002' } }).then(v => {
      if (v.success && v.data) {
        const d = v.data.map(item => ({ value: item.code + '', label: item.name }))
        setDictList(d)
      }
    })

    let d = []
    if (tokenParams && tokenParams != 'null') {
      Request.get(Path.getCommunityIds, { params: { projectType: 1 }, headers: { authorization: 'Bearer ' + tokenParams } }).then(v => {
        if (v.success) {
          d = v.data.map(item => ({ value: item.id, label: item.name }))
          setCommunitys(d)
        }
      })
    } else {
      Request.get(Path.getCommunityData, { params: { type: 1 } }).then(v => {
        if (v.success) {
          d = v.data.map(item => ({ value: item.id, label: item.name }))
          setCommunitys(d)
        }
      })
    }
  }

  const submitHouse = value => {
    setShowModal(false)
    setOnlyHouse(value)
  }

  const selectHouse = () => {
    if (onlyHouse?.houseInfo?.communityId) {
      setShowModal(true)
    } else {
      Toast.info('请先选择小区!', 2)
    }
  }

  const bindHouse = () => {
    if (!userName) {
      Toast.info('请填写姓名!', 2)
      return
    }
    if (identity && onlyHouse?.houseInfo?.houseId) {
      const params = {
        type: identity,
        houseId: onlyHouse?.houseInfo?.houseId,
        name: userName,
        approveUserId: employeeId || null,
        tmpId,
      }
      Request.post(Path.createOwner, params, { headers: { authorization: 'Bearer ' + token } }).then(v => {
        if (v.success) {
          Toast.info('房屋绑定成功!', 1, () => {
            history.push(`/HouseInfo${window.location.search}&employeetoken=${employeetoken}`)
          })
        }
      })
    } else {
      Toast.info('请选择房屋和身份!', 2)
    }
  }
  return (
    <div style={{ height: '100vh' }}>
      <List className={['infoList', 'ownerList']} style={{ margin: '15px 15px 0 15px' }}>
        <Picker data={communitys} cols={1} value={[onlyHouse?.houseInfo?.communityId]} onChange={v => setOnlyHouse({ houseInfo: { ...onlyHouse.houseInfo, communityId: v[0] } })}>
          <Item arrow="horizontal">小区</Item>
        </Picker>
        <Item arrow="horizontal" extra={onlyHouse?.houseInfo.buildingName ? onlyHouse?.houseInfo?.buildingName + onlyHouse?.houseInfo?.unitName + onlyHouse?.houseInfo?.floorName + onlyHouse?.houseInfo?.name : <span style={{ color: '#999999' }}>请选择房屋</span>}
          onClick={() => selectHouse()}>楼栋/单元/房号</Item>
        <Picker className='changeColorPicker' data={dictList} cols={1} value={[identity]} onChange={v => setIdentity(v[0])}>
          <Item arrow="horizontal">身份</Item>
        </Picker>
        <InputItem
          style={{ textAlign: 'right' }}
          placeholder="请填写姓名"
          onChange={v => setUserName(Trim(v))}
          value={userName}
        >真实姓名</InputItem>
        {userInfo?.phone && <Item extra={userInfo?.phone}>手机号</Item>}
      </List>
      <Button style={{ background: '#00AAFF', color: '#fff', margin: '30px 15px 0' }} onClick={() => bindHouse()}>绑定房屋</Button>
      {showModal && <HouseModal onlyHouse={onlyHouse} showModal={showModal} onClose={() => setShowModal(false)} onSubmit={value => submitHouse(value)} />}
    </div>
  )
}

export default BindHouse
