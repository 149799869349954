
// 工单列表

import { useEffect, useState } from 'react';
import { List, Divider, Image, Space, ImageViewer, Steps, Button, DatePicker, Popup, Toast, TextArea, ImageUploader, Picker, Dialog } from 'antd-mobile-v5';
import { useHistory } from "react-router-dom"
import Request from '../../utils/request'
import Path from '../../utils/path'
import moment from 'moment'
import { getQueryString, Trim } from '../../utils/utils'
import Style from './orderDetail.module.css'

const { Step } = Steps
moment.locale('zh-cn')
const OrderDetail = () => {
  let history = useHistory()
  const [dataObj, setDataObj] = useState({})
  const [communitys, setCommunitys] = useState({})
  const [workType, setWorkType] = useState([])
  const [workValue, setWorkValue] = useState('')
  const [taker, setTaker] = useState([])
  const [takeValue, setTakeValue] = useState('')
  const [popupVisible, setPopupVisible] = useState('')
  const [fileList, setFileList] = useState([])
  const [textareaValue, setTextareaValue] = useState('')

  document.title = '工单详情'

  // 工单id
  const caseId = getQueryString('caseId')
  // 管家id
  const houseKeepId = getQueryString('userid')
  // 管家token
  const token = getQueryString('employeetoken')
  // 模板id
  const tmpId = getQueryString('tmpId')

  useEffect(() => {
    Request.get(Path.getDetail, { params: { caseId } }).then(res => {
      if (res.success && res.data) {
        setDataObj(res.data)
      }
    })
  }, [])

  useEffect(() => {
    if (communitys?.id) {
      Request.get(Path.getCommunityByEmployee, { params: { communityId: communitys.id } }).then(v => {
        if (v.success && Array.isArray(v.data)) {
          const d = v.data.map(item => ({ phone: item.phone, value: item?.id + '', label: item?.name }))
          setTaker([d])
        }
      })
    }
  }, [communitys])

  const gotoDetail = caseId => {
    history.push(`/orderDetail${window.location.search}&caseId=${caseId}`)
  }

  const buttonClick = async (item) => {
    if (item.flowId == 'flow5') {  // 接单
      DatePicker.prompt({
        title: '计划完成时间',
        confirmText: '提交',
        min: moment().toDate(),
        max: moment().add(3, 'M').toDate(),
        onConfirm: (dateTime) => {
          const params = {
            cutoffTime: moment(dateTime).format('YYYY-MM-DD'),
            jobId: dataObj?.runTask?.jobId,
            nextFlowId: item.flowId,
            tmpId,
          }
          nextWork(params)
        }
      })
    } else if (item.flowId == 'flow6' || item.flowId == 'flow8' || item.flowId == 'flow4' || item.flowId == 'flow2' ||
       item.flowId == 'flow3' || item.flowId == 'flow7') {  // 2关单 3派单 4拒接 6完成 7退回重做 8重新分配
      if (item.flowId == 'flow8' || item.flowId == 'flow3') {
        getTypeAndUsers()
      }
      setPopupVisible(item.flowId)
    } else if(item.flowId == 'flow9') {   // 结单
      const result = await Dialog.confirm({
        content: '是否确定结单?',
      })
      if (result) {
        const params = {
          jobId: dataObj?.runTask?.jobId,
          nextFlowId: item.flowId,
          tmpId,
        }
        nextWork(params)
      }
    }
  }
  const getTypeAndUsers = () => {
    Request.get(Path.getCommunityIds, { params: { projectType: 1 }, headers: { authorization: 'Bearer ' + token } }).then(v => {
      if (v.success && Array.isArray(v.data)) {
        setCommunitys(v.data[0])
      }
    })
    Request.get(Path.workstateGetDictList, { params: { type: 2001 } }).then(v => {
      if (v.success && Array.isArray(v.data)) {
        const d = v.data.map(item => ({ value: item.code + '', label: item.name }))
        setWorkType([d])
      }
    })
  }
  const submitWork = async (flowId) => {
    if(flowId == 'flow6' || flowId == 'flow4' || flowId == 'flow2' || flowId == 'flow7') {    // 完成工单 拒接工单 关单 退回
      if (Trim(textareaValue)) {
        let transactionId = ''
        if (fileList.length > 0) {
          const params = {
            fileUrls: fileList.map(v => v.url),
            type: 1,
          }
          await Request.post(Path.addEnclosure, params).then(v => {
            if (v.success) {
              transactionId = v.data
            }
          })
        }
        const params = {
          content: textareaValue,
          jobId: dataObj?.runTask?.jobId,
          nextFlowId: flowId,
          transactionId,
          tmpId,
        }
        nextWork(params)
      } else {
        Toast.show('请输入内容!', 1)
      }
    } else if (flowId == 'flow8' || flowId == 'flow3') {
      if(workValue && takeValue) {
        const nextUser = taker[0].find(v=>takeValue == v.value)
        const params = {
          jobId: dataObj?.runTask?.jobId,
          nextFlowId: flowId,
          nextUserId: takeValue,
          nextUserName: nextUser.label,
          nextUserPhone: nextUser.phone,
          systematicsCode: workValue,
          tmpId,
        }
        nextWork(params)
      } else {
        Toast.show('请选择类型和接单人!', 1)
      }
    }
  }
  const nextWork = params => {
    Request.post(Path.workNext, params).then(v => {
      if (v.success) {
        Toast.show('成功!', 1)
        history.push(`/workOrder${window.location.search}`)
      }
    })
  }
  const changeColor = index => {
    const length = dataObj?.runTask?.flowList.length
    if (length == 1) {
      return 'primary'
    } else if (length == 2) {
      if (index == 1) {
        return 'primary'
      }
    } else {
      if (index == 1) {
        return 'warning'
      } else if (index == 2) {
        return 'primary'
      }
    }
    return 'default'
  }
  const mockUpload = async file => {
    let formData = new FormData()
    formData.append('files', file)
    let fileUrl = {}
    await Request.post(Path.upload, formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(v => {
      if (v.success && v.data.length > 0) {
        fileUrl = { url: v.data[0] }
        setFileList(val => [...val, { url: v.data[0] }])
      }
    })
    Toast.clear()
    return fileUrl
  }
  const LimitCount = () => {
    return (
      <ImageUploader
        value={fileList}
        onChange={setFileList}
        upload={mockUpload}
        maxCount={6}
        showUpload={fileList.length < 6}
        onCountExceed={exceed => {
          Toast.show(`最多选择 6 张图片，你多选了 ${exceed} 张`)
        }}
      />
    )
  }
  return (
    <div style={{background: '#fff', height: '100vh'}}>
      <div className={Style.bodyDiv}>
        <List className={[Style.bodyList, 'listV5']}>
          <List.Item extra={dataObj?.sn}>
            工单编号
          </List.Item>
          <List.Item extra={dataObj?.gmtCreate && moment(dataObj?.gmtCreate).format('YYYY-MM-DD HH:mm:ss')}>
            提交时间
          </List.Item>
          <List.Item extra={dataObj?.houseName}>
            房间
          </List.Item>
          <List.Item extra={dataObj?.reportUser?.userName}>
            发布人
          </List.Item>
          <List.Item extra={dataObj?.reportUser?.userPhone}>
            手机号
          </List.Item>
        </List>
        <Divider style={{ borderStyle: 'dashed' }}>内容</Divider>
        <div style={{ marginBottom: '10px' }}>{dataObj?.content}</div>
        {(Array.isArray(dataObj.images) && dataObj?.images.length > 0) && <Space wrap>
          {dataObj.images.map((item, index) => (
            <Image key={index} src={item} width={80} height={80} style={{ borderRadius: 8 }} onClick={() => ImageViewer.Multi.show({ images: dataObj.images, defaultIndex: index })} />
          ))}
        </Space>}
        <Divider style={{ borderStyle: 'dashed' }}>处理进度</Divider>
        <Steps direction='vertical'>
          <Step title={`${moment(dataObj.gmtCreate).format('YYYY-MM-DD HH:mm:ss')} ${dataObj?.reportUser?.userName}发布工单`} status='process' />
          {
            Array.isArray(dataObj.hiTaskList) && dataObj.hiTaskList.map(item => (
              <Step key={item.taskId} title={`${moment(item.gmtCreate).format('YYYY-MM-DD HH:mm:ss')} ${item?.jobUser?.userName}${item?.name}`} status='process'
                description={<div>
                  {item.content && <div style={{ fontSize: '12px' }}>{item.content}</div>}
                  {(Array.isArray(item.images) && item.images.length > 0) && <Space wrap style={{ marginTop: '5px' }}>
                    {item.images.map((v, i) => (
                      <Image key={i} src={v} width={50} height={50} style={{ borderRadius: 8 }} onClick={() => ImageViewer.Multi.show({ images: item.images, defaultIndex: i })} />
                    ))}
                  </Space>}
                </div>}
              />
            ))
          }
        </Steps>
      </div>
      <div className={Style.buttonDivSty}>
        {(dataObj?.runTask?.jobUser?.userId == houseKeepId && Array.isArray(dataObj?.runTask?.flowList) && dataObj?.runTask?.flowList.length > 0) && dataObj?.runTask?.flowList.map((item, index) => (
          <Button key={index} color={changeColor(index)} style={{ flex: 1, borderRadius: 0 }} onClick={() => buttonClick(item)}>{item.name}</Button>
        ))}
      </div>
      {
        popupVisible && <Popup
          visible={!!popupVisible}
          onMaskClick={() => setPopupVisible('')}
        >
          <div style={{ padding: '0 15px 15px' }}>
            <div className={Style.popupStyle}>
              <div style={{fontSize: '16px'}} onClick={() => setPopupVisible('')}>取消</div>
              <div style={{ fontSize: '16px' }}>{popupVisible == 'flow6' ? '完成工单' : popupVisible == 'flow6' ? 
                '派单' : popupVisible == 'flow4'?'拒接原因':popupVisible == 'flow2'?'关单原因':popupVisible == 'flow7'?'退回原因':''}</div>
              <div style={{ color: '#1677ff', fontSize: '16px' }} onClick={() => submitWork(popupVisible)}>提交</div>
            </div>
            {
              (popupVisible == 'flow6' || popupVisible == 'flow4' || popupVisible == 'flow2' || popupVisible == 'flow7') && <>
                <TextArea
                  placeholder='请输入'
                  maxLength={200}
                  rows={3}
                  style={{ '--font-size': '14px' }}
                  value={textareaValue}
                  onChange={val => setTextareaValue(val)}
                />
                <Space direction='vertical' style={{ marginTop: '15px' }}>
                  <LimitCount />
                </Space></>
            }
            {
              (popupVisible == 'flow8' || popupVisible == 'flow3') && <>
                <List className={[Style.bodyList, 'listV5']}>
                  <List.Item extra={workType.length > 0 && workType[0].find(v=>v.value == workValue)?.label || '请选择'} onClick={async () => {
                    const value = await Picker.prompt({
                      columns: workType,
                    })
                    value && setWorkValue(value[0])
                  }}>
                    类型
                  </List.Item>
                  <List.Item extra={taker.length > 0 && taker[0].find(v=>v.value == takeValue)?.label || '请选择'} onClick={async () => {
                    const value = await Picker.prompt({
                      columns: taker,
                    })
                    value && setTakeValue(value[0])
                  }}>
                    接单人
                  </List.Item>
                </List>
              </>
            }
          </div>
        </Popup>
      }
    </div>
  );
}

export default OrderDetail;
