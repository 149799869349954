import { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom"
import { List, Modal, Toast, Button } from 'antd-mobile';
import { getQueryString } from '../../utils/utils'
import Request from '../../utils/request'
import Path from '../../utils/path'
import HouseIcon from '../../images/houseIcon.png'
import Style from './index.module.css'

const alert = Modal.alert;

const HouseInfo = props => {
  let history = useHistory()
  // 业主token
  const token = getQueryString('token')
  const employeetoken = getQueryString('employeetoken')

  document.title = '我的房屋'

  const [houseData, setHouseData] = useState([])

  useEffect(() => {
    getHouseInfo()
  }, [])

  const getHouseInfo = () => {
    Request.get(Path.getHouseList, { headers: { authorization: 'Bearer ' + token } }).then(v => {
      if (v.success && v?.data.length > 0) {
        setHouseData(v.data)
      } else {
        setHouseData([])
      }
    })
  }

  const deleteHouse = id => {
    const params = {
      id
    }
    Request.post(Path.deleteMyHouse, params, { headers: { authorization: 'Bearer ' + token } }).then(v => {
      if (v.success) {
        Toast.info('成功!')
        getHouseInfo()
      }
    })
  }

  const clickHouse = item => {
    if (item.status.code != 2) {
      const value = {
        id: item.id,
        houseInfo: item.houseInfo,
        type: item.type,
        status: item.status,
      }
      history.push(`/HouseDetail${window.location.search}`, value)
    }
  }
  return (
    <div style={{ height: '100vh' }}>
      <List style={{ height: 'calc(100% - 47px)', margin: '0 15px', overflow: 'auto' }} className='houseList'>
        {houseData && houseData.map(item => (
          <div key={item.id} className={Style.cardBackground} onClick={() => clickHouse(item)}>
            <div className={Style.divBox}>
              <div className={Style.divBoxTop}>
                <img src={HouseIcon} style={{ width: '22px', height: '22px', marginRight: '10px' }} />
                <span>{item?.houseInfo?.communityName}</span>
              </div>
              <span style={{ marginLeft: '32px' }}>{item?.houseInfo?.buildingName + item?.houseInfo?.unitName + item?.houseInfo?.floorName + item?.houseInfo?.name}</span>
            </div>
            <div className={Style.divBox}>
              {item.status.code == 1 ? <div className={Style.infoButton}>查看详情</div> :
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span className={Style.divBoxBottom}>{item.status.name}</span>
                  <span className={Style.divBoxStatus} onClick={(e) => {
                    e.stopPropagation()
                    alert('提示', `确定${item.status.code == 0 ? '取消审核' : '删除'}?`, [
                      { text: '取消', onPress: () => console.log('cancel') },
                      { text: '确定', onPress: () => deleteHouse(item.id) },
                    ])
                  }
                  }>{item.status.code == 0 ? '取消审核' : '删除'}</span>
                </div>
              }
            </div>
          </div>
        ))}
      </List>
      <Button style={{ background: '#00AAFF', color: '#fff', margin: '0 15px' }} onClick={() => history.push(`/BindHouse${window.location.search}`, { typeFlag: 'houseInfo' })}>绑定新房屋</Button>
    </div>
  )
}

export default HouseInfo
