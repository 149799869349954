import { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom"
import { Button, Toast, Loading, InfiniteScroll, List, Divider } from 'antd-mobile-v5';
import { getQueryString, Fmoney } from '../../utils/utils'
import Request from '../../utils/request'
import Path from '../../utils/path'
import moment from 'moment'
import RadioRound from '../../images/radioRound.png'
import RadioChecked from '../../images/radioChecked.png'

let count = 0
const Bill = () => {
  let history = useHistory()
  // 业主token
  const token = getQueryString('token')

  document.title = '开具发票'

  const [data, setData] = useState([])
  const [recordId, setRecordId] = useState([])
  const [hasMore, setHasMore] = useState(false)
  const [page, setPage] = useState({ pageTotal: 1, pageNo: 0 })
  const [flag, setFlag] = useState(false)

  useEffect(() => {
    getListData()
  }, [])

  const getListData = async (pageNo = 1) => {
    await Request.get(Path.getOrderPrintList, { params: { pageSize: 10, pageNo }, headers: { authorization: 'Bearer ' + token } }).then(res => {
      if (pageNo = 1) {
        setFlag(true)
      }
      if (res.success && Array.isArray(res.data.data)) {
        setPage({ pageTotal: res?.data?.pageTotal, pageNo: res?.data?.pageNo })
        setData(val => [...val, ...res?.data?.data])
        if (count < res?.data?.pageTotal && res?.data?.pageTotal > 1) {
          setHasMore(true)
        } else {
          setHasMore(false)
        }
      }
    })
  }

  const handleRadio = sn => {
    if (recordId.includes(sn)) {
      setRecordId(val => val.filter(item => item != sn))
    } else {
      setRecordId(val => [...val, sn])
    }
  }

  const allRadio = () => {
    if (recordId.length >= data.length) {
      setRecordId([])
    } else {
      const all = data.map(item => item.sn)
      setRecordId(all)
    }
  }

  const loadMore = async () => {
    if (count > 0 && count < page.pageTotal) {
      await getListData(++count)
    } else {
      count++
    }
  }

  const countAmount = () => {
    const amount = recordId.reduce((pre, cur) => {
      const obj = data.find(item => item.sn == cur)
      if (obj) {
        pre += Number(obj.amount)
      }
      return pre
    }, 0)
    return Fmoney(amount)
  }

  const invoicing = () => {
    if (recordId.length <= 0) {
      Toast.show({ content: '请先选择订单!' })
    } else {
      const value = recordId.map(item => data.find(v => v.sn == item))
      history.push(`/Invoicing${window.location.search}`, { billArr: value })
    }
  }

  const InfiniteScrollContent = ({ hasMore }) => {
    return (
      <>
        {page.pageTotal > 0 ? hasMore ? (
          <>
            <span>Loading</span>
            <Loading />
          </>
        ) : (
          <span>--- 我是有底线的 ---</span>
        ) : (
          <span>--- 暂无数据 ---</span>
        )}
      </>
    )
  }

  return (
    <div style={{ height: '100vh', overflow: 'hidden' }}>
      <div style={{ padding: '15px', display: 'flex', justifyContent: 'space-between', fontSize: '14px', alignItems: 'center' }}>
        <div style={{ fontSize: '16px' }}>开具发票</div>
        <div onClick={() => history.push(`/CompleBill${window.location.search}`)}>{`开票历史 >`}</div>
      </div>
      {data.length > 0 ? <div style={{ height: '100%' }}>
        <div style={{ overflowY: 'auto', height: 'calc(100% - 40px)' }}>
          <List className='list_v5_v2' style={{ background: 'transparent', border: 0 }}>
            {data.map((item, index) => (
              <div key={index} style={{ display: 'flex', alignItems: 'center', background: '#fff', padding: '15px', fontSize: '14px', marginBottom: '5px' }}
                onClick={() => handleRadio(item.sn)}>
                <img src={recordId.includes(item.sn) ? RadioChecked : RadioRound} style={{ width: '18px', height: '18px', marginRight: '15px' }} />
                <div style={{ display: 'flex', flexDirection: 'column', fontSize: '14px', flex: 1 }}>
                  <div style={{ color: '#B3B3B3', marginBottom: '5px' }}>{item.sn}</div>
                  <div>{item.houseName}</div>
                  <div style={{ color: '#A2A2A2', marginTop: '10px', fontSize: '12px' }}>{item.payTime && moment(item.payTime).format('YYYY-MM-DD HH:mm')}</div>
                </div>
                <div style={{ color: '#101010', fontSize: '16px', marginLeft: '15px' }}>{`${item.amount && Fmoney(item.amount)}元`}</div>
              </div>
            ))}
          </List>
          <InfiniteScroll loadMore={() => loadMore()} hasMore={hasMore} threshold={200}>
            <InfiniteScrollContent hasMore={hasMore} />
          </InfiniteScroll>
        </div>
        <div style={{ position: 'absolute', bottom: 0, background: '#fff', width: '100%', padding: '5px 0' }}>
          <div style={{ display: 'flex', alignItems: 'center', fontSize: '14px' }}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', borderRight: '1px solid #ccc', paddingRight: '10px', margin: '0 10px' }}
              onClick={() => allRadio()}>
              <img src={recordId.length >= data.length ? RadioChecked : RadioRound} style={{ width: '18px', height: '18px', marginBottom: '5px' }} />
              <div>全选</div>
            </div>
            <div style={{ flex: 1 }}>共 {recordId.length} 个订单, {countAmount()}元</div>
            <Button style={{ marginRight: '15px' }} color='primary' onClick={() => invoicing()}>去开票</Button>
          </div>
        </div>
      </div> : flag ?
        <Divider style={{ borderStyle: 'dashed' }}>
          暂无订单
        </Divider> : ''}
    </div>
  )
}

export default Bill
