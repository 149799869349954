import { useEffect, useState } from 'react'
import { Toast } from 'antd-mobile';
import { Route, Redirect, useHistory } from "react-router-dom"
import { getQueryString } from '../../utils/utils'
import Request from '../../utils/request'
import Path from '../../utils/path'

const AuthRoute = props => {
  const wx = window.jWeixin
  let history = useHistory()
  // 业主token
  const token = getQueryString('token')
  const appId = getQueryString('appId')

  const [myChilrder, setMyChildren] = useState('')
  
  useEffect(() => {
    Request.get(Path.getConfig, { params: { url: window.location.href.split('#')[0] }, headers: { 'app-id': appId } }).then(res => {
      if (res.success) {
        const { appId, nonceStr, signature, timestamp } = res.data
        wx.config({
          beta: true,  // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId, // 必填，公众号的唯一标识
          timestamp, // 必填，生成签名的时间戳
          nonceStr, // 必填，生成签名的随机串
          signature,// 必填，签名
          jsApiList: ["chooseImage", "getLocalImgData"], // 必填，需要使用的JS接口列表
        })
        wx.ready(function () {
          
        })
        wx.error(function (res) {
          console.log('config失败')
        })
      }
    })
    if (token) {
      Request.get(Path.getUserInfo, { headers: { authorization: 'Bearer ' + token } }).then(v => {
        if (v.success) {
          localStorage.setItem("userInfo", JSON.stringify(v.data))
          setMyChildren(<Route {...props}></Route>)
        } else {
          Toast.info('登录失败,请重试!')
        }
      })
    } else {
      setMyChildren(<Redirect to={{ pathname: '/Login', search:`${window.location.search}`, state: { url: window.location.href } }}></Redirect>)
    }
  }, [])
  return (
    <div>
      {myChilrder}
    </div>
  )
}

export default AuthRoute
