import { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom"
import { Button, Toast } from 'antd-mobile';
import { Dialog, Divider } from 'antd-mobile-v5';
import { getQueryString, Fmoney } from '../../utils/utils'
import Request from '../../utils/request'
import Path from '../../utils/path'
import RadioRound from '../../images/radioRound.png'
import RadioChecked from '../../images/radioChecked.png'
import { DownOutline, UpOutline } from 'antd-mobile-icons'

const PayMoney = ({ location }) => {
  const wx = window.jWeixin
  const { state } = location
  let history = useHistory()
  // 业主token
  const token = getQueryString('token')

  const wxOpenId = getQueryString('wxOpenId')

  const appId = getQueryString('appId')
  document.title = '缴费详情'

  const [data, setData] = useState({})
  const [recordId, setRecordId] = useState([])
  const [recordAmount, setRecordAmount] = useState('')
  const [downIndex, setDownIndex] = useState([])
  useEffect(() => {
    Request.get(Path.getPayHouseList, { params: { houseId: state?.houseId, status: 1 } }).then(v => {
      if (v.success && v.data) {
        setData(v.data)
        const dd = v?.data?.chargeBillDtos
        if (Array.isArray(dd)) {
          const recordArr = dd.reduce((pre, cur) => {
            if (Array.isArray(cur?.chargeBillInfo)) {
              pre.push([cur?.chargeBillInfo.length])
            }
            return pre
          }, [])
          setRecordId(recordArr)
        }
      }
    })
  }, [])

  useEffect(() => {
    if (Array.isArray(data?.chargeBillDtos)) {
      const amount = data?.chargeBillDtos.reduce((pre, cur, i)=>{
        return pre + cur.chargeBillInfo.filter((item, index) => index < recordId[i]).reduce((p, c) => p + c?.amount, 0)
      }, 0)
      setRecordAmount(amount)
    }
  }, [recordId])

  const clickRecord = (index, i) => {
    const newRecord = recordId.map((item, ire) => {
      if(index == ire) {
        return [i]
      }
      return item
    })
    setRecordId(newRecord)
  }

  const handleOutLine = index => {
    if(downIndex.includes(index)) {
      const d = downIndex.filter(item=>item != index)
      setDownIndex(d)
    } else {
      setDownIndex([...downIndex, index])
    }
  }

  const allRadio = (itemData, index) => {
    if (Array.isArray(itemData)) {
      const allLength = itemData.length
      const newRecord = recordId.map((item, i) => {
        if(index == i) {
          if(allLength == item[0]) {    // 已经全部选中
            return [0]
          }
          return [allLength]
        }
        return item
      })
      setRecordId(newRecord)
    }
  }

  const payMoneyWeixin = () => {
    if (recordAmount > 0) {
      Dialog.confirm({
        title: '确认缴费?',
        content: <div style={{ textAlign: 'center' }}>
          <div style={{ marginBottom: '5px' }}>社区: {state?.communityName}</div>
          <div style={{ marginBottom: '5px' }}>房号: {state?.house}</div>
          <div style={{ marginBottom: '5px' }}>业主: {data?.customerName}</div>
        </div>,
        onConfirm: () => {
          Request.get(Path.getConfig, { params: { url: window.location.href.split('#')[0] }, headers: { 'app-id': appId } }).then(res => {
            if (res.success) {
              const { appId, nonceStr, signature, timestamp } = res.data
              wx.config({
                beta: true,  // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
                debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                appId, // 必填，公众号的唯一标识
                timestamp, // 必填，生成签名的时间戳
                nonceStr, // 必填，生成签名的随机串
                signature,// 必填，签名
                jsApiList: ["chooseWXPay"], // 必填，需要使用的JS接口列表
              })
              wx.ready(function () {
                console.log('config成功');
                choosePay()
              })
              wx.error(function (res) {
                Toast.info('支付配置失败!')
                console.log('config失败');
              })
            }
          })
        }
      })
    } else {
      Toast.info('请选择费用!', 2)
    }
  }

  const choosePay = () => {
    const contractNo = data?.chargeBillDtos.reduce((pre, cur, i)=>{
      const arr = cur.chargeBillInfo.filter((item, index) => index < recordId[i]).map(v => v?.contractNo)
      pre.push(...arr)
      return pre
    }, [])

    const params = {
      channel: 4,
      contractNo,
      houseId: state.houseId,
      wxOpenId,
    }
    Request.post(Path.createChargeBill, params, { headers: { authorization: 'Bearer ' + token, 'app-id': appId } }).then(v => {
      if (v.success && v.data) {
        startPay(v, contractNo)
      }
    })
  }
  const startPay = (v, contractNo) => {
    const { appId, timeStamp, nonceStr, signType, sign } = v?.data?.charge
    if (window.WeixinJSBridge) {
      window.WeixinJSBridge.invoke('getBrandWCPayRequest', {
        appId,     //公众号ID，由商户传入     
        timeStamp,         //时间戳，自1970年以来的秒数     
        nonceStr, //随机串     
        "package": v?.data?.charge.package,
        signType,         //微信签名方式：     
        "paySign": sign //微信签名 
      }, function (res) {
        if (res.err_msg == "get_brand_wcpay_request:ok") {
          Toast.info('支付成功!', 1, () => {
            history.push(`/PropertyPay${window.location.search}`)
          })
        } else {
          console.log('失败');
          console.log('res ', res);
          // Toast.info('取消支付!')
          const paramNo = {
            contractNo
          }
          Request.post(Path.removeContractNo, paramNo)
        }
      });
    } else {
      Toast.info('请用微信浏览器打开!')
    }
  }
  return (
    <div>
      <div style={{ margin: '15px 15px 0', display: 'flex', flexDirection: 'column' }}>
        <div style={{ flex: 1, background: '#ffffff', padding: '25px 15px 15px', marginBottom: '15px', borderRadius: '6px' }}>
          <div style={{ fontSize: '16px', color: '#303133' }}>待缴金额</div>
          <div style={{ marginTop: '25px', marginBottom: '24px', fontSize: '30px', color: '#00AAFF' }}>
            <span>{data?.amount ? Fmoney(data?.amount) : ''}</span>
            <span style={{ fontSize: '14px', marginLeft: '10px' }}>元</span>
          </div>
          <Divider />
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px', fontSize: '14px', color: '#666666' }}>
            <div>社区</div>
            <div style={{ color: '#303133' }}>{state?.communityName}</div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '14px', marginBottom: '20px', color: '#666666' }}>
            <div>房号</div>
            <div style={{ color: '#303133' }}>{state?.house}</div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '14px', color: '#666666' }}>
            <div>业主姓名</div>
            <div style={{ color: '#303133' }}>{data?.customerName}</div>
          </div>
        </div>
        <div style={{ flex: 2, display: 'flex', flexDirection: 'column', marginBottom: '20px' }}>
          <div style={{ background: '#ffffff', padding: '15px', fontSize: '14px', borderRadius: '6px', flexGrow: 1, marginBottom: '15px' }}>
            <div style={{ color: '#333333', marginBottom: '20px', fontSize: '15px' }}>费用明细</div>
            {
              data?.chargeBillDtos && data.chargeBillDtos.map((item, index) => (
                <div key={index}>
                  <div style={{ display: 'flex' }}>
                    <img src={recordId[index] == item?.chargeBillInfo?.length ? RadioChecked : RadioRound} style={{ width: '16px', height: '16px', paddingRight: '10px' }}
                      onClick={()=>allRadio(item?.chargeBillInfo, index)}
                    />
                    <span style={{ flex: 1 }}>{item.chargeItemName}</span>
                    <span>￥{Fmoney(item.chargeAmount)}</span>
                    <span style={{ paddingLeft: '5px' }} onClick={()=>handleOutLine(index)}>{downIndex.includes(index)?<UpOutline />:<DownOutline />}</span>
                  </div>
                  <div style={{ marginLeft: '25px', marginTop: '5px' }}>
                  {
                    (Array.isArray(item.chargeBillInfo) && downIndex.includes(index)) && item.chargeBillInfo.map((v, i) => {
                      return <div key={i} style={{ display: 'flex', padding: '11px 0' }} onClick={() => clickRecord(index, i+1)}>
                        <img src={(recordId[index] >= i+1) ? RadioChecked : RadioRound} style={{ width: '16px', height: '16px' }} />
                        <div style={{ color: '#666666', marginLeft: '10px', flex: 1 }}>{v.billDate}</div>
                        <span style={{ color: '#333' }}>￥{Fmoney(v.amount)}</span>
                      </div>
                    })
                  }
                  </div>
                  {data.chargeBillDtos.length - 1 > index && <Divider style={{ borderStyle: 'dashed' }} />}
                </div>
              ))
            }
          </div>
          <Button style={{ background: '#00AAFF', color: '#fff', marginTop: '20px' }} onClick={() => payMoneyWeixin()}>{`立即支付 ${recordAmount > 0 ? Fmoney(recordAmount) + '元' : ''}`}</Button>
        </div>
      </div>
    </div>
  )
}

export default PayMoney
