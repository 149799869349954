import { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom"
import { InfiniteScroll, List, Loading, Ellipsis, Dialog, Toast } from 'antd-mobile-v5';
import { getQueryString } from '../../../utils/utils'
import Request from '../../../utils/request'
import Path from '../../../utils/path'
import moment from 'moment'

let count = 1
const Article = () => {
  let history = useHistory()
  // 管家token
  const token = getQueryString('employeetoken')
  // appid
  const appid = getQueryString('appid')
  // 企业微信应用id
  const agentid = getQueryString('agentid')
  // 从哪个页面跳转过来
  const type = getQueryString('type')

  document.title = '物业瞬间'
  const wx = window.jWeixin

  let oldData = []
  const [dataList, setDataList] = useState([])
  const [hasMore, setHasMore] = useState(false)
  const [page, setPage] = useState({ pageTotal: 1, pageNo: 0 })

  useEffect(() => {
    getListData(1)
    count = 1
    oldData = []
  }, [])

  useEffect(() => {
    Request.get(Path.jsapiSign, { params: { url: window.location.href.split('#')[0], agentId: agentid } }).then(res => {
      if (res.success) {
        const { appId, nonceStr, signature, timestamp } = res.data
        wx.config({
          beta: true,  // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId, // 必填，公众号的唯一标识
          timestamp, // 必填，生成签名的时间戳
          nonceStr, // 必填，生成签名的随机串
          signature,// 必填，签名
          jsApiList: ["agentConfig"], // 必填，需要使用的JS接口列表
        })
        wx.ready(function () {
          console.log('config成功')
          myAgentConfig()
        })
        wx.error(function (res) {
          console.log('config失败')
        })
      }
    })
  }, [])

  const getListData = async (pageNo) => {
    await Request.get(Path.searchMy, { params: { pageSize: 20, pageNo }, headers: { authorization: 'Bearer ' + token } }).then(res => {
      if (res.success && Array.isArray(res.data.data)) {
        setPage({ pageTotal: res?.data?.pageTotal, pageNo: res?.data?.pageNo })
        oldData = [...oldData, ...res?.data?.data]
        const dd = oldData.reduce((pre, cur) => {
          const index = pre.findIndex(item => item.year == moment(cur.issueTime).year())
          if (index > -1) {
            pre[index]['value'].push(cur)
          } else {
            pre.push({
              year: moment(cur.issueTime).year(),
              value: [cur]
            })
          }
          return pre
        }, [])
        setDataList(dd)
        if (count < res?.data?.pageTotal) {
          setHasMore(true)
        }
      }
    })
  }
  const myAgentConfig = () => {
    Request.get(Path.agentJsapiSign, { params: { url: window.location.href.split('#')[0], agentId: agentid } }).then(res => {
      if (res.success) {
        const { appId, nonceStr, signature, timestamp } = res.data
        wx.agentConfig({
          corpid: appId, // 必填，企业微信的corpid，必须与当前登录的企业一致
          agentid, // 必填，企业微信的应用id
          timestamp, // 必填，生成签名的时间戳
          nonceStr, // 必填，生成签名的随机串
          signature,// 必填，签名
          jsApiList: ['sendChatMessage'], // 必填，需要使用的JS接口列表
          success: function (res) {
            // 回调
          },
          fail: function (res) {
            if (res.errMsg.indexOf('function not exist') > -1) {
              alert('版本过低请升级')
            }
          }
        })
      }
    })
  }
  const loadMore = async () => {
    if (count < page.pageTotal) {
      await getListData(++count)
    } else {
      setHasMore(false)
    }
  }

  const InfiniteScrollContent = ({ hasMore }) => {
    return (
      <>
        {page.pageTotal > 0 ? hasMore ? (
          <>
            <span>Loading</span>
            <Loading />
          </>
        ) : (
          <span>--- 我是有底线的 ---</span>
        ) : (
          <span>--- 暂无数据 ---</span>
        )}
      </>
    )
  }

  const deleteById = (e, id) => {
    e.stopPropagation()
    Dialog.confirm({
      title: '提示',
      content: '确定删除?',
      onConfirm: () => {
        Request.post(Path.deleteInfoMoment, { id }).then(v => {
          if (v.success) {
            count = 1
            oldData = []
            getListData(1)
            Toast.show('删除成功!', 1)
          }
        })
      }
    })
  }

  const shareInfo = (e, id, content) => {
    e.stopPropagation()
    Request.get(Path.getMsgTemplate, { params: { id: 5 } }).then(v => {
      if (v.success && v.data) {
        const news = {
          link: `${v.data?.url}&articleId=${id}`, //H5消息页面url 必填
          title: v.data?.title, //H5消息标题
          desc: content, //H5消息摘要
          imgUrl: v.data?.picurl, //H5消息封面图片URL
        }
        let flagWX = wx
        if (window.WeixinJSBridge) {
          flagWX = window.WeixinJSBridge
        }
        flagWX.invoke('sendChatMessage', {
          msgtype: "news", //消息类型，必填
          enterChat: true, //为true时表示发送完成之后顺便进入会话，仅移动端3.1.10及以上版本支持该字段
          news,
        }, function (res) {
          if (res.err_msg == 'sendChatMessage:ok') {
            //发送成功
          } else {
            console.log('分享失败');
          }
        })
      } else {
        Toast.info('分享失败!', 2)
      }
    })
  }
  const updateInfo = (e, da) => {
    e.stopPropagation()
    history.push(`/addArticle${window.location.search}`, da)
  }
  return (
    <div style={{ background: '#fff', height: '100vh' }}>
      <List style={{ margin: '0 15px', background: 'transparent' }}>
        {Array.isArray(dataList) && dataList.map((item, index) => (
          <div key={index}>
            <h3>{item?.year}年</h3>
            {item?.value && item.value.map((v, i) => (
              <div key={v.id} style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }} onClick={()=>history.push(`/detailArticle${window.location.search}&articleId=${v.id}`)}>
                <div style={{ width: '40px', fontWeight: 'bold', marginRight: '5px' }}>
                  {(i == 0 || moment(v.issueTime).format('YYYY-MM-DD') != moment(item.value[i - 1]['issueTime']).format('YYYY-MM-DD')) && <div>
                    <div style={{ fontSize: '12px', textAlign: 'center' }}>{moment(v.issueTime).month() + 1}月</div>
                    <div style={{ fontSize: '13px', textAlign: 'center' }}>{moment(v.issueTime).date()}日</div>
                  </div>}
                </div>
                <div style={{ flex: 1, display: 'flex' }}>
                  {v?.picture && <img src={v?.pictureList[0]} style={{ width: '70px', height: '70px', marginRight: '10px' }} />}
                  <div style={{ flex: 1, fontSize: '14px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <Ellipsis direction='end' rows={2} content={v?.content} />
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}>
                      <div style={{ color: '#0072BA', fontSize: '12px' }}>
                        <span style={{ marginRight: '15px' }} onClick={e => deleteById(e, v?.id)}>删除</span>
                        <span onClick={e=>updateInfo(e, v)}>编辑</span>
                      </div>
                      {(!type || type!=1) && <div onClick={e => shareInfo(e, v.id, v?.content)} style={{
                        borderRadius: '4px', border: '1px solid rgb(255, 167, 0)', width: '50px', height: '20px',
                        textAlign: 'center', color: '#FFA700', fontSize: '12px', lineHeight: '18px'
                      }}>分享</div>}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))}
      </List>
      <InfiniteScroll loadMore={() => loadMore()} hasMore={hasMore} threshold={100}>
        <InfiniteScrollContent hasMore={hasMore} />
      </InfiniteScroll>
    </div>
  )
}

export default Article
