import { useEffect } from 'react'
import { getQueryString } from '../../utils/utils'
import Request from '../../utils/request'
import Path from '../../utils/path'

const Answer = props => {
  // 业主token
  const token = getQueryString('token')
  useEffect(async () => {
    // 获取本地存储登录数据
    let communityName = '', houseName = '', phone = '';
    if (token) {

      let res = await Request.get(Path.getUserInfo, { headers: { authorization: 'Bearer ' + token } });
      console.log('getUserInfo: ', res)
      if (res.success) {
        phone = res.data.phone
      }
      res = await Request.get(Path.getHouseList, { headers: { authorization: 'Bearer ' + token } });
      console.log('getHouseList: ', res, res.data.length)
      if (res.success) {
        if (res.data.length > 0) {
          let houseInfo = null
          if (res.data[0]?.status.code == '1') {
            houseInfo = res.data[0]?.houseInfo
          }
          res.data.forEach(item => {
            if (item.status.code == '1' && item.type.code == '1') {
              houseInfo = item.houseInfo
            }
          })

          communityName = houseInfo?.communityName || ''
          houseName = communityName + (houseInfo?.buildingName || '') + (houseInfo?.unitName || '') + (houseInfo?.name || '')
        }
      }
    }

    let url = 'http://cxhwjx.actiiot.com/vj/OXrRPaJ.aspx?'
    if (communityName != undefined && communityName!='') {
      url = url + '&q14='
      url = url + communityName
    }
    if (houseName != undefined && houseName!='') {
      url = url + '&q15='
      url = url + houseName
    }
    if (phone != undefined && phone!='') {
      url = url + '&q16='
      url = url + phone
    }

    console.log('url: ', url)

    window.location.href = url

  }, [])
  return (
    <div>
    </div>
  )
}

export default Answer
