
// 工单列表

import { useEffect, useState } from 'react';
import { InfiniteScroll, List, Tabs, Loading, Card, Ellipsis } from 'antd-mobile-v5';
import { useHistory } from "react-router-dom"
import Request from '../../utils/request'
import Path from '../../utils/path'
import moment from 'moment'
import { getQueryString } from '../../utils/utils'
import Addres from '../../images/addres.png'
import Time from '../../images/time.png'

let count = 1
const WorkOrder = () => {
  let history = useHistory()
  const [dataList, setDataList] = useState([])
  const [activeKey, setActiveKey] = useState('0')
  const [hasMore, setHasMore] = useState(false)
  const [page, setPage] = useState({ pageTotal: 1, pageNo: 0 })

  document.title = '工单'

  // 管家id
  const houseKeepId = getQueryString('userid')
  // 管家token
  const token = getQueryString('employeetoken')
  // 类型(从哪个页面过来，不同的类型有不同的接口和显示效果)
  const urlType = getQueryString('urlType')
  // 业主id
  const ownerId = getQueryString('ownerId')

  useEffect(() => {
    getListData(1)
  }, [activeKey])

  useEffect(() => {
    count = 1
  }, [])

  const getListData = async (pageNo, type) => {
    if (urlType == 'valet') {   // 管家查看代客报修历史记录
      await Request.get(Path.getRepairListInfo, { params: { pageSize: 10, pageNo }, headers: { authorization: 'Bearer ' + token } }).then(res => {
        if (res.success && Array.isArray(res.data.data)) {
          setPage({ pageTotal: res?.data?.pageTotal, pageNo: res?.data?.pageNo })
          if (type == 'load') {
            setDataList(val => [...val, ...res?.data?.data])
          } else {
            setDataList(res.data.data || [])
          }
          if (count < res?.data?.pageTotal) {
            setHasMore(true)
          }
        }
      })
    } else if (urlType == 'owner') {   // 管家查看业主报修历史记录
      await Request.get(Path.getOwnerRepairInfo, { params: { pageSize: 10, userId: getQueryString('ownerWorkId'), pageNo } }).then(res => {
        if (res.success && Array.isArray(res.data.data)) {
          setPage({ pageTotal: res?.data?.pageTotal, pageNo: res?.data?.pageNo })
          if (type == 'load') {
            setDataList(val => [...val, ...res?.data?.data])
          } else {
            setDataList(res.data.data || [])
          }
          if (count < res?.data?.pageTotal) {
            setHasMore(true)
          }
        }
      })
    } else if (urlType == 'ownerSelf') {    // 业主自己查看报修列表
      const status = activeKey > 0 ? activeKey : undefined
      await Request.get(Path.getWorkOrderList, { params: { reportUserId: ownerId, pageSize: 10, status, pageNo } }).then(res => {
        if (res.success && Array.isArray(res.data.data)) {
          setPage({ pageTotal: res?.data?.pageTotal, pageNo: res?.data?.pageNo })
          if (type == 'load') {
            setDataList(val => [...val, ...res?.data?.data])
          } else {
            setDataList(res.data.data || [])
          }
          if (count < res?.data?.pageTotal) {
            setHasMore(true)
          }
        }
      })
    } else {
      const status = activeKey > 0 ? activeKey : undefined
      await Request.get(Path.getWorkOrderList, { params: { jobUserId: houseKeepId, pageSize: 10, status, pageNo } }).then(res => {
        if (res.success && Array.isArray(res.data.data)) {
          setPage({ pageTotal: res?.data?.pageTotal, pageNo: res?.data?.pageNo })
          if (type == 'load') {
            setDataList(val => [...val, ...res?.data?.data])
          } else {
            setDataList(res.data.data || [])
          }
          if (count < res?.data?.pageTotal) {
            setHasMore(true)
          }
        }
      })
    }
  }

  const loadMore = async () => {
    if (count < page.pageTotal) {
      await getListData(++count, 'load')
    } else {
      setHasMore(false)
    }
  }

  const gotoDetail = caseId => {
    history.push(`/orderDetail${window.location.search}&caseId=${caseId}`)
  }

  const InfiniteScrollContent = ({ hasMore }) => {
    return (
      <>
        {page.pageTotal > 0 ? hasMore ? (
          <>
            <span>Loading</span>
            <Loading />
          </>
        ) : (
          <span>--- 我是有底线的 ---</span>
        ) : (
          <span>--- 暂无数据 ---</span>
        )}
      </>
    )
  }
  return (
    <>
      {(urlType != 'valet' && urlType != 'owner') && <Tabs activeKey={activeKey} onChange={key => {
        count = 1;
        setActiveKey(key)
        setHasMore(false)
        setPage({ pageTotal: 1, pageNo: 0 })
      }} style={{ background: '#fff', fontSize: '16px' }}>
        <Tabs.TabPane title='全部' key='0' />
        <Tabs.TabPane title='待派单' key='1' />
        <Tabs.TabPane title='待办结' key='2' />
        <Tabs.TabPane title='已办结' key='9' />
      </Tabs>}
      <List style={{ margin: '10px 15px 0', background: 'transparent' }}>
        {dataList.map((item, index) => (
          <Card key={index} style={{ borderRadius: '6px', fontSize: '14px', marginBottom: '15px' }} onClick={() => gotoDetail(item?.caseId)}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '10px' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img src={Time} style={{ width: '20px', height: '25px', marginRight: '5px' }} />
                <span>{moment(item.gmtCreate).format('YYYY-MM-DD HH:mm:ss')}</span>
              </div>
              <div style={{ color: '#FFBF00' }}>{item?.status?.name}</div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
              <img src={Addres} style={{ width: '20px', height: '25px', marginRight: '5px' }} />
              <span>{item?.houseName || item?.communityName}</span>
            </div>
            <Ellipsis direction='end' content={item?.content} />
          </Card>
        ))}
      </List>
      <InfiniteScroll loadMore={() => loadMore()} hasMore={hasMore} threshold={100}>
        <InfiniteScrollContent hasMore={hasMore} />
      </InfiniteScroll>
    </>
  );
}

export default WorkOrder;
