import { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom"
import { List, Toast, Picker } from 'antd-mobile';
import { TextArea, Card, ImageUploader, Space, Button, Toast as Toastv5 } from 'antd-mobile-v5';
import { getQueryString, Trim } from '../../utils/utils'
import Request from '../../utils/request'
import Path from '../../utils/path'

const Item = List.Item;

const ReportForRepair = () => {
  let history = useHistory()
  // 业主token
  const token = getQueryString('token')
  const appId = getQueryString('appId')
  // 模板id
  const tmpId = getQueryString('tmpId')

  document.title = '报事报修'

  const [fileList, setFileList] = useState([])
  const [textareaValue, setTextareaValue] = useState('')
  const [houseData, setHouseData] = useState([])
  const [houseValue, setHouseValue] = useState('')
  const [userInfo, setUserInfo] = useState({})

  useEffect(() => {
    Request.get(Path.getHouseList, { params: { status: 1 }, headers: { authorization: 'Bearer ' + token } }).then(v => {
      if (v.success && Array.isArray(v.data)) {
        const d = v.data.map(item => ({ value: item?.houseInfo?.houseId, label: item?.houseInfo?.communityName + item?.houseInfo?.buildingName+item?.houseInfo?.unitName+item?.houseInfo?.floorName+item?.houseInfo?.name }))
        setHouseData(d)
        setHouseValue(d[0]?.value || '')
      }
    })
    Request.get(Path.getUserInfo, { headers: { authorization: 'Bearer ' + token } }).then(v => {
      if (v.success && v.data) {
        setUserInfo(v.data)
      }
    })
  }, [])

  const onSubmit = async () => {
    if (houseValue && Trim(textareaValue)) {
      let transactionId = ''
      if (fileList.length > 0) {
        const params = {
          fileUrls: fileList.map(v => v.url),
          type: 1,
        }
        await Request.post(Path.addEnclosure, params).then(v => {
          if (v.success) {
            transactionId = v.data
          }
        })
      }
      const params = {
        appId,
        content: Trim(textareaValue),
        houseId: houseValue,
        transactionId,
        userId: userInfo?.id,
        userName: userInfo?.name || userInfo?.nick,
        userPhone: userInfo?.phone,
        tmpId,
      }
      Request.post(Path.createByOwner, params).then(v => {
        if (v.success) {
          Toast.info('工单创建成功!', 1, () => {
            history.push(`/workOrder${window.location.search}&urlType=ownerSelf&ownerId=${userInfo?.id}`)
          })
        }
      })
    } else {
      Toast.info('还有信息没有填完!', 2)
    }
  }
  const mockUpload = async file => {
    let formData = new FormData()
    formData.append('files', file)
    let fileUrl = {}
    await Request.post(Path.upload, formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(v => {
      if (v.success && v.data.length > 0) {
        fileUrl = { url: v.data[0] }
        setFileList(val => [...val, { url: v.data[0] }])
      }
    })
    Toastv5.clear()
    return fileUrl
  }
  const LimitCount = () => {
    return (
      <ImageUploader
        value={fileList}
        onChange={setFileList}
        upload={mockUpload}
        maxCount={6}
        showUpload={fileList.length < 6}
        onCountExceed={exceed => {
          Toast.show(`最多选择 6 张图片，你多选了 ${exceed} 张`)
        }}
      />
    )
  }

  return (
    <div style={{ height: '100vh', padding: '15px', overflowY: 'auto' }}>
      <List className={['infoList', 'ownerList']}>
        <Picker data={houseData} cols={1} value={[houseValue]} onChange={v => setHouseValue(v[0])}>
          <Item arrow="horizontal">房间号</Item>
        </Picker>
      </List>
      <Card title='报修内容' style={{ marginTop: '20px' }}>
        <TextArea
          placeholder='请输入报修内容'
          showCount
          maxLength={500}
          rows={4}
          style={{ '--font-size': '14px' }}
          value={textareaValue}
          onChange={val => setTextareaValue(val)}
        />
      </Card>
      <Card title={<div>上传照片 <span style={{ color: '#999' }}>(最多上传6张照片)</span></div>} style={{ marginTop: '20px' }}>
        <Space direction='vertical'>
          <LimitCount />
        </Space>
      </Card>
      <div style={{ display: 'flex', margin: '30px 15px 0' }}>
        <Button style={{ flex: 1, borderRadius: 0, border: 0 }} onClick={() => onSubmit()} color='primary'>提交</Button>
        <Button style={{ flex: 1, borderRadius: 0, border: 0 }} onClick={() => history.push(`/workOrder${window.location.search}&urlType=ownerSelf&ownerId=${userInfo?.id}`)}>报修记录</Button>
      </div>
    </div>
  )
}

export default ReportForRepair
