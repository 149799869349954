import { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom"
import { List } from 'antd-mobile';
import moment from 'moment'
import { getQueryString, Fmoney } from '../../utils/utils'
import Request from '../../utils/request'
import Path from '../../utils/path'
import Style from './index.module.css'
import Down from '../../images/down.png'
import Up from '../../images/up.png'

const Item = List.Item;

const PaymentRecord = () => {
  let history = useHistory()
  // 业主userId
  const userId = getQueryString('userId')

  document.title = '缴费记录'

  const [data, setData] = useState([])
  const [clickIndex, setClickIndex] = useState('none')
  useEffect(() => {
    Request.get(Path.getHistoricalQywx, {params:{userId}}).then(v => {
      if (v.success && v?.data.length > 0) {
        const payData = v.data
        const newData = payData.reduce((pre, cur) => {
          const index = pre.findIndex(item => item.year == moment(cur.payTime).year())
          if (index > -1) {
            pre[index]['data'].push(cur)
            return pre
          } else {
            const a = {
              year: moment(cur.payTime).year(),
              data: [cur]
            }
            pre.push(a)
          }
          return pre
        }, [])
        newData.sort((a, b)=> b.year - a.year)
        setData(newData)
      }
    })
  }, [])

  const clickMonth = index => {
    if(clickIndex == index) {
      setClickIndex('none')
    } else {
      setClickIndex(index)
    }
  }

  const billDate = date => {
    let returnDate = '未获取账单周期'
    if(date && date.indexOf('~') > -1) {
      returnDate = moment(date.split('~')[0]).format('YYYY-MM')
    }
    return returnDate
  }
  return (
    <div style={{ height: '100vh' }}>
      <List className='houseList' style={{ margin: '0 15px', height: '100%', overflow: 'auto' }}>
        {
          data && data.map((item, i) => (
            <div key={item.year} className={Style.divClass}>
              <div style={{ marginBottom: '15px', paddingLeft: '5px', display: 'flex', justifyContent: 'space-between' }}>
                <div>{item.year}年</div>
                {i == 0 && <div onClick={()=>history.push(`/Bill${window.location.search}`)}>{`申请开票>>`}</div>}
              </div>
              {
                item.data.map((v, index) => (
                  <div key={index} className={Style.divContent} onClick={()=>clickMonth(item.year+''+index)}>
                    <div className={Style.contentClass} style={{ marginBottom: '15px' }}>
                      <span style={{ color: '#666666' }}>{v.houseName}</span>
                      <span style={{ fontSize: '18px' }}>{Fmoney(v.amount)}元</span>
                    </div>
                    <div className={Style.contentClass} style={{ fontSize: '12px', color: '#666666' }}>
                      <div>
                        <span>{moment(v.payTime).format('MM-DD HH:mm')}</span>
                        <span style={{ color: '#00AAFF', marginLeft: '20px' }}>{v.payMethod}</span>
                      </div>
                      <div style={{display: 'flex', alignItems: 'center'}}>
                        <span>{v.months}</span>
                        <img src={clickIndex == item.year+''+index ? Up :Down} style={{ width: '12px', height: '7px', marginLeft: '5px' }} />
                      </div>
                    </div>
                    {
                      clickIndex == item.year+''+index && v?.billList.map((oneItem, indexRecord)=>(
                        <div key={indexRecord} className={Style.oneDiv}>
                          <div>
                            <span style={{marginRight: '10px'}}>{billDate(oneItem.billDate)}</span>
                            <span>{oneItem.chargeItemName}</span>
                          </div>
                          <span style={{color: '#333333'}}>{Fmoney(oneItem.billAmount)}元</span>
                        </div>
                      ))
                    }
                  </div>
                ))
              }
            </div>
          ))
        }
      </List>
    </div>
  )
}

export default PaymentRecord
