// 云之家首页

import { useEffect } from 'react';
import { useHistory } from "react-router-dom"
import { Card, Grid } from 'antd-mobile-v5';
import Request from '../../../../utils/request'
import { getQueryString } from '../../../../utils/utils'
import Path from '../../../../utils/path'
import AddressBook from '../../../../images/yunzhijia/addressBook.png'
import Approval from '../../../../images/yunzhijia/approval.png'
import Article from '../../../../images/yunzhijia/article.png'
import Learn from '../../../../images/yunzhijia/learn.png'
import Notify from '../../../../images/yunzhijia/notify.png'
import ProcessAssistant from '../../../../images/yunzhijia/processAssistant.png'
import SelfHelp from '../../../../images/yunzhijia/selfHelp.png'
import SignIn from '../../../../images/yunzhijia/signIn.png'

const FrontPage = () => {
  document.title = '云之家工作台'
  const wx = window.jWeixin
  let history = useHistory()
  // 企业微信应用id
  const agentid = getQueryString('agentid')
  const at = getQueryString('at')

  // { label: '签到', value: '900001', img: SignIn },
  const data = [{ label: '流程助手', value: '500739343', img: ProcessAssistant }, { label: '智能审批', value: '10104', img: Approval },
  { label: '学习平台', value: '500669544', img: Learn }, { label: '知识中心', value: '10879', img: Notify },
  { label: '员工自助', value: '500739347', img: SelfHelp }, { label: '团队管理', value: '500739348', img: Article }]

  useEffect(() => {
    Request.get(Path.jsapiSign, { params: { url: window.location.href.split('#')[0], agentId: agentid } }).then(res => {
      if (res.success) {
        const { appId, nonceStr, signature, timestamp } = res.data
        wx.config({
          beta: true,  // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId, // 必填，公众号的唯一标识
          timestamp, // 必填，生成签名的时间戳
          nonceStr, // 必填，生成签名的随机串
          signature,// 必填，签名
          jsApiList: ["openDefaultBrowser"], // 必填，需要使用的JS接口列表
        })
        wx.ready(function () {
          console.log('config成功')
        })
        wx.error(function (res) {
          console.log('config失败')
        })
      }
    })
  }, [])
  const yunzhijiaUrl = yunAppId => {
    Request.get(Path.lappAccess, { params: { appId: yunAppId, deviceType: 2, gl: at } }).then(gl => {
      if (gl.success && gl?.data?.url) {
        let goUrl = gl.data.url
        if (goUrl.indexOf('cloudhub') <= -1 && goUrl.indexOf('http') <= -1) {
          goUrl = 'https://www.yunzhijia.com' + goUrl
        }
        // 判断是pc还是移动端 pc端用默认浏览器打开
        if (isPC()) {  // PC
          wx.invoke('openDefaultBrowser', { 'url': goUrl }, function (res) {
            if (res.err_msg != "openDefaultBrowser:ok") {
              //错误处理
              console.log('openDefaultBrowser 错误');
              window.location.href = goUrl
            }
          })
        } else {
          window.location.href = goUrl
        }
      } else {
        history.push(`/yLogin${window.location.search}`)
      }
    })
  }

  const isPC = () => {
    //判断当前入口是PC端还是APP端 
    let flag = navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i) == null ? true : false
    return flag  // true PC
  }
  return (
    <div style={{ background: '#fff', height: '100vh' }}>
      <Card style={{ paddingTop: '30px' }}>
        <Grid columns={4} gap={[8, 60]}>
          {data.map((item, index) =>
            <Grid.Item key={index} style={{ textAlign: 'center', fontSize: '12px' }}>
              <img style={{ width: '40px', height: '40px' }} src={item.img} onClick={() => yunzhijiaUrl(item.value)} />
              <div style={{ marginTop: '5px' }}>{item.label}</div>
            </Grid.Item>)}
        </Grid>
      </Card>
    </div>
  )
}

export default FrontPage
