import { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom"
import { InputItem, List, Picker, Toast, Modal, Button } from 'antd-mobile';
import { Tag, Space, Card } from 'antd-mobile-v5';
import { RightOutline } from 'antd-mobile-icons'
import Request from '../../../utils/request'
import Path from '../../../utils/path'
import { getQueryString, Trim } from '../../../utils/utils'
import HouseModal from '../../../components/houseModal'
import AddHouse from '../../../images/addHouse.png'
import Delete from '../../../images/delete.png'
import Share from '../../../images/share.png'
import Style from './index.module.css'

const Item = List.Item;
const alert = Modal.alert;

const OwnerInfo = () => {
  let history = useHistory()
  const [userName, setUserName] = useState('')
  const [userData, setUserData] = useState({})           // 数据库获取到的用户信息 
  const [userQywxData, setUserQywxData] = useState({})   // 企业微信获取到的用户信息
  const [dictList, setDictList] = useState([])
  const [houseInfo, setHouseInfo] = useState([])
  const [onlyHouse, setOnlyHouse] = useState({})
  const [showModal, setShowModal] = useState(false)
  const [communitys, setCommunitys] = useState([])
  const [userId, setUserId] = useState('')
  const [remark, setRemark] = useState('')
  const [workOrder, setWorkOrder] = useState({})
  const [ownerTag, setOwnerTag] = useState([])

  // 管家token
  const token = getQueryString('employeetoken')
  // 企业微信应用id
  const agentid = getQueryString('agentid')
  // appid
  const appid = getQueryString('appid')
  // 管家的userid
  const houseKeepId = getQueryString('userid')

  const wx = window.jWeixin
  useEffect(() => {
    Request.get(Path.jsapiSign, { params: { url: window.location.href.split('#')[0], agentId: agentid } }).then(res => {
      if (res.success) {
        const { appId, nonceStr, signature, timestamp } = res.data
        wx.config({
          beta: true,  // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId, // 必填，公众号的唯一标识
          timestamp, // 必填，生成签名的时间戳
          nonceStr, // 必填，生成签名的随机串
          signature,// 必填，签名
          jsApiList: ["closeWindow", "agentConfig"], // 必填，需要使用的JS接口列表
        })
        wx.ready(function () {
          console.log('config成功')
          myAgentConfig()
        })
        wx.error(function (res) {
          console.log('config失败')
        })
      }
    })
  }, [])

  useEffect(() => {
    // 获取unionId
    if (userId) {
      Request.get(Path.getExternalContact, { params: { externalUserId: userId } }).then(v => {
        console.log('ss ', v)
        if (v.success && v?.data?.externalContact?.unionId) {
          setUserQywxData(v?.data?.externalContact)
          Request.get(Path.getUserInfoByUnionId, { params: { unionId: v.data.externalContact.unionId }, headers: { 'app-id': appid } }).then(v => {
            if (v.success && v?.data) {
              setUserData(v.data)
              setUserName(v.data?.name)
            } else {
              Toast.info('业主信息获取失败!')
            }
          })
          if (v?.data?.followedUsers) {
            const dd = v.data.followedUsers.find(item => item.userId == houseKeepId)
            setRemark(dd?.remark)
            setOwnerTag(dd?.tags)
          }
        } else {
          Toast.info('业主信息获取失败!')
        }
      })

      Request.get(Path.authGetDictList, { params: { type: '4002' } }).then(v => {
        if (v.success && v.data) {
          const d = v.data.map(item => ({ value: item.code + '', label: item.name }))
          setDictList(d)
        }
      })
    }
  }, [userId])

  const myAgentConfig = () => {
    Request.get(Path.agentJsapiSign, { params: { url: window.location.href.split('#')[0], agentId: agentid } }).then(res => {
      if (res.success) {
        const { appId, nonceStr, signature, timestamp } = res.data
        wx.agentConfig({
          corpid: appId, // 必填，企业微信的corpid，必须与当前登录的企业一致
          agentid, // 必填，企业微信的应用id
          timestamp, // 必填，生成签名的时间戳
          nonceStr, // 必填，生成签名的随机串
          signature,// 必填，签名
          jsApiList: ['getCurExternalContact', 'sendChatMessage', 'openUserProfile'], // 必填，需要使用的JS接口列表
          success: function (res) {
            // 回调
            wx.invoke('getCurExternalContact', {
            }, function (item) {
              if (item.err_msg == "getCurExternalContact:ok") {
                setUserId(item?.userId)
              } else {
                //错误处理
                console.log('getCurExternalContact错误');
              }
            });
          },
          fail: function (res) {
            console.log('myAgentConfig失败');
            if (res.errMsg.indexOf('function not exist') > -1) {
              alert('版本过低请升级')
            }
          }
        })
      }
    })
  }

  useEffect(() => {
    if (userData?.id) {
      // 报修统计数据
      Request.get(Path.getTotalOwnerWorkOrder, { params: { userId: userData?.id } }).then(v => {
        if (v.success && v.data) {
          setWorkOrder(v.data)
        }
      })
    }
    if (userData?.token) {
      // 业主所有的房间信息
      Request.get(Path.getHouseList, { params: { status: 1 }, headers: { authorization: 'Bearer ' + userData.token } }).then(v => {
        if (v.success && v.data) {
          // 获取管家项目 匹配显示管家所管辖的项目的业主房间信息
          Request.get(Path.getCommunityIds, { params: { projectType: 1 }, headers: { authorization: 'Bearer ' + token } }).then(item => {
            if (item.success) {
              const data = v.data.reduce((pre, cur) => {
                if (item?.data.some(item => item.id == cur?.houseInfo?.communityId)) {
                  pre.push(cur)
                }
                return pre
              }, [])
              setCommunitys(item.data)
              setHouseInfo(data)
            }
          })
        }
      })
    } else {
      Request.get(Path.getCommunityIds, { params: { projectType: 1 }, headers: { authorization: 'Bearer ' + token } }).then(item => {
        if (item.success) {
          setCommunitys(item.data)
        }
      })
    }
  }, [userData])

  const addHouseInfo = () => {
    let id = houseInfo.length
    const house = {
      communityName: '',
      buildingName: '',
      unitName: '',
      floorName: '',
      name: '',
    }
    const type = {
      code: '',
    }
    setHouseInfo([...houseInfo, { id, houseInfo: house, type }])
  }

  const deleteHouse = id => {
    const data = houseInfo.reduce((pre, cur) => {
      if (cur.id != id) {
        pre.push(cur)
      }
      return pre
    }, [])
    setHouseInfo(data)
  }

  const pickerChange = (value, id) => {
    let arrItem = houseInfo.find(item => item.id == id)
    if (arrItem) {
      arrItem.type.code = value[0]
    }
    setHouseInfo(Array.from(new Set([...houseInfo, arrItem])))
  }

  const modalHouse = item => {
    // 存项目房屋id
    if (item) {
      setOnlyHouse({ id: item.id, houseInfo: item.houseInfo })
      // 弹出modal
      setShowModal(true)
    }
  }

  const submitHouse = value => {
    setShowModal(false)
    const data = houseInfo.map(item => {
      if (item.id == value.id) {
        item.houseInfo = value.houseInfo
      }
      return item
    })
    setHouseInfo(data)
  }

  const submitInfo = () => {
    if (!userName) {
      Toast.info('请填写业主姓名!', 1)
      return
    }
    
    const houseList = houseInfo.reduce((pre, cur) => {
      if (cur?.houseInfo?.id && cur?.type?.code) {
        pre.push({
          houseId: cur.houseInfo.id,
          userType: cur.type.code,
        })
      }
      return pre
    }, [])
    
    if (!Array.isArray(houseList) || houseList.length <= 0) {
      Toast.info('房屋数据不完整!', 1)
      return
    }

    let params = {
      houseList,
      name: userName,
      userId: userData?.id,
      avatar: userQywxData?.avatar,
      unionId: userQywxData?.unionId,
      nick: userQywxData?.name,
    }
    Request.post(Path.saveOwnerInfoQywx, params, { headers: { authorization: 'Bearer ' + token, 'app-id': appid } }).then(v => {
      if (v.success) {
        Toast.info('保存成功!', 2)
      }
    })
  }

  // const closeHtml = () => {
  //   alert('提示', '客户资料未保存,确定离开吗?', [
  //     { text: '取消', onPress: () => console.log('cancel') },
  //     {
  //       text: '离开',
  //       onPress: () => {
  //         wx.closeWindow()
  //       }
  //     },
  //   ])
  // }

  const goMessage = () => {
    if (window.WeixinJSBridge) {
      window.WeixinJSBridge.call('closeWindow') // 微信，只是微信就用这个API。
    } else {
      wx.closeWindow();
    }
  }

  const share = (id) => {
    Request.get(Path.getMsgTemplate, { params: { id } }).then(v => {
      if (v.success && v.data) {
        const news = {
          link: `${v.data?.url}&employeeId=${houseKeepId}`, //H5消息页面url 必填
          title: v.data?.title, //H5消息标题
          desc: v.data?.detail, //H5消息摘要
          imgUrl: v.data?.picurl, //H5消息封面图片URL
        }
        let flag = wx
        if (window.WeixinJSBridge) {
          flag = window.WeixinJSBridge // 微信，只是微信就用这个API。
        }
        flag.invoke('sendChatMessage', {
          msgtype: "news", //消息类型，必填
          enterChat: true, //为true时表示发送完成之后顺便进入会话，仅移动端3.1.10及以上版本支持该字段
          news,
        }, function (res) {
          if (res.err_msg == 'sendChatMessage:ok') {
            //发送成功
          } else {
            console.log('分享失败');
          }
        })
      } else {
        Toast.info('分享失败!', 2)
      }
    })
  }

  const getTags = () => {
    let flagWX = wx
    if (window.WeixinJSBridge) {
      flagWX = window.WeixinJSBridge
    }
    flagWX.invoke('openUserProfile', {
      "type": 2, //1表示该userid是企业成员，2表示该userid是外部联系人
      "userid": userId //可以是企业成员，也可以是外部联系人
    }, function (res) {
      if (res.err_msg != "openUserProfile:ok") {
        //错误处理
      }
    })
  }
  return (
    <div style={{ height: '100vh' }}>
      <div style={{ height: 'calc(100% - 47px)', overflowY: 'auto' }}>
        <div className={Style.contentTop}>
          <div className={Style.contentTopRight}>
            <img src={userData?.headpath || userQywxData?.avatar} style={{ width: '59px', height: '59px', borderRadius: '6px' }} />
            <div className={Style.contentTopRightTwo}>
              <div style={{ color: '#333333', fontSize: '14px' }}>{userData?.nick || userQywxData?.name}</div>
              <div style={{ color: '#666666', fontSize: '12px' }}>{remark}</div>
            </div>
          </div>
          <div style={{lineHeight: '59px', color: '#0000ff', fontSize: '12px' }} onClick={() => share(6)}>邀请问卷</div>
          <div className={Style.buttonWeChat} onClick={() => goMessage()}>进入聊天</div>
        </div>

        <List className='infoList'>
          <Item extra={<span style={{ color: '#666666' }}>{userData?.nick || userQywxData?.name}</span>}>微信昵称</Item>
          <InputItem
            style={{ textAlign: 'right' }}
            placeholder="真实姓名"
            onChange={v => setUserName(Trim(v))}
            value={userName}
            clear={true}
          >真实姓名</InputItem>
          {userData?.phone && <Item extra={userData?.phone}>手机号</Item>}
        </List>
        {
          Array.isArray(houseInfo) && houseInfo.map((item, i) => (
            <List key={i} className='infoList' style={{ marginTop: '25px' }}>
              <img src={Delete} style={{ width: '19px', height: '19px', position: 'absolute', top: '-8px', marginLeft: '50%', zIndex: 1 }} onClick={() => deleteHouse(item.id)} />
              <Item arrow="horizontal" extra={item.houseInfo ? item.houseInfo?.communityName + item.houseInfo?.buildingName + item.houseInfo?.unitName +
                item.houseInfo?.floorName + item.houseInfo?.name : ''} onClick={() => modalHouse(item)}>认证房号</Item>
              <Picker data={dictList} cols={1} value={[item?.type?.code]} onChange={v => pickerChange(v, item.id)}>
                <Item arrow="horizontal">身份类型</Item>
              </Picker>
            </List>
          ))
        }
        <div className={Style.bottomDiv}>
          <div style={{ display: 'flex', alignItems: 'center' }} onClick={() => addHouseInfo()}>
            <img src={AddHouse} style={{ width: '19px', height: '19px' }} />
            <span style={{ marginLeft: '5px' }}>增加一个房屋</span>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }} onClick={() => share(1)}>
            <img src={Share} style={{ width: '19px', height: '19px' }} />
            <span style={{ marginLeft: '5px' }}>邀客户添加</span>
          </div>
        </div>
        <Card title='客户数据' style={{ marginTop: '15px', borderRadius: 0 }}>
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <div style={{ flex: 1, textAlign: 'center' }}>
              <div style={{ fontSize: '16px', marginBottom: '10px' }}>{workOrder?.complaintCount || 0}</div>
              <div>投诉</div>
            </div>
            <div style={{ flex: 1, textAlign: 'center' }} onClick={() => userData?.id && history.push(`/workOrder${window.location.search}&urlType=owner&ownerWorkId=${userData?.id}`)}>
              <div style={{ fontSize: '16px', marginBottom: '10px' }}>{workOrder?.repairCount || 0}</div>
              <div>报修</div>
            </div>
          </div>
        </Card>
        <Card title='客户标签' style={{ marginTop: '15px', borderRadius: 0 }}>
          <div style={{ display: 'flex', alignItems: 'center' }} onClick={() => getTags()}>
            <div style={{ flex: 1, marginRight: '15px' }}>
              <Space wrap>
                {(Array.isArray(ownerTag) && ownerTag.length > 0) ? ownerTag.map(tagValue => <span>{tagValue?.tagName}</span>) : <span style={{ color: '#999' }}>还没有为客户定义标签!</span>}
              </Space>
            </div>
            <RightOutline fontSize={20} />
          </div>
        </Card>
      </div>
      <Button style={{ background: '#4873C0', borderRadius: 0, bottom: 0, color: '#fff' }} onClick={() => submitInfo()}>保存</Button>
      {showModal && <HouseModal onlyHouse={onlyHouse} communityData={communitys} showModal={showModal} onClose={() => setShowModal(false)} onSubmit={value => submitHouse(value)} />}
    </div>
  );
}

export default OwnerInfo;
