import { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom"
import { List } from 'antd-mobile';
import { Toast, Divider } from 'antd-mobile-v5';
import { getQueryString, Fmoney } from '../../utils/utils'
import Request from '../../utils/request'
import Path from '../../utils/path'
import HouseIcon from '../../images/houseIcon.png'
import ZhangDan from '../../images/zhangDan.png'
import Style from './index.module.css'

const PropertyPay = props => {
  let history = useHistory()
  // 业主token
  const token = getQueryString('token')
  // 管家的userid
  const houseKeepId = getQueryString('userid')
  // 企业微信应用id
  const agentid = getQueryString('agentid')

  const [paymentList, setPaymentList] = useState([])
  const [userId, setUserId] = useState('')
  const [userData, setUserData] = useState({})
  const [flag, setFlag] = useState(false)

  const wx = window.jWeixin
  document.title = '物业缴费'
  useEffect(() => {
    if (token) {
      getList(token)
    } else if (houseKeepId) {
      Request.get(Path.jsapiSign, { params: { url: window.location.href.split('#')[0] , agentId: agentid } }).then(res => {
        if (res.success) {
          const { appId, nonceStr, signature, timestamp } = res.data
          wx.config({
            beta: true,  // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId, // 必填，公众号的唯一标识
            timestamp, // 必填，生成签名的时间戳
            nonceStr, // 必填，生成签名的随机串
            signature,// 必填，签名
            jsApiList: ["agentConfig"], // 必填，需要使用的JS接口列表
          })
          wx.ready(function () {
            console.log('config成功')
            myAgentConfig()
          })
          wx.error(function (res) {
            console.log('config失败')
          })
        }
      })
    }
  }, [])

  useEffect(() => {
    // 获取unionId，业主token
    if (userId) {
      Request.get(Path.getExternalContact, { params: { externalUserId: userId } }).then(v => {
        if (v.success && v?.data?.externalContact?.unionId) {
          const params = {
            avatar: v?.data?.externalContact?.avatar,
            unionId: v?.data?.externalContact?.unionId,
            nick: v?.data?.externalContact?.name,
          }
          Request.post(Path.createUserByUnionId, params).then(v => {
            if (v.success && v?.data) {
              setUserData(v.data)
              getList(v.data?.token)
            } else {
              Toast.info('业主信息获取失败!')
            }
          })
        } else {
          Toast.info('业主信息获取失败!')
        }
      })
    }
  }, [userId])

  const getList = ownerToken => {
    Request.get(Path.getFeeByUser, { headers: { authorization: 'Bearer ' + ownerToken } }).then(v => {
      if (v.success && v?.data.length > 0) {
        setPaymentList(v.data)
      }
      setFlag(true)
    })
  }
  const myAgentConfig = () => {
    Request.get(Path.agentJsapiSign, { params: { url: window.location.href.split('#')[0], agentId: agentid } }).then(res => {
      if (res.success) {
        const { appId, nonceStr, signature, timestamp } = res.data
        wx.agentConfig({
          corpid: appId, // 必填，企业微信的corpid，必须与当前登录的企业一致
          agentid, // 必填，企业微信的应用id
          timestamp, // 必填，生成签名的时间戳
          nonceStr, // 必填，生成签名的随机串
          signature,// 必填，签名
          jsApiList: ['getCurExternalContact', 'sendChatMessage'], // 必填，需要使用的JS接口列表
          success: function (res) {
            // 回调
            wx.invoke('getCurExternalContact', {
            }, function (item) {
              if (item.err_msg == "getCurExternalContact:ok") {
                setUserId(item?.userId)
              } else {
                //错误处理
                console.log('getCurExternalContact错误');
              }
            });
          },
          fail: function (res) {
            if (res.errMsg.indexOf('function not exist') > -1) {
              alert('版本过低请升级')
            }
          }
        })
      }
    })
  }

  const payMoney = item => {
    if (token) {
      const values = {
        communityName: item?.houseInfo?.communityName,
        house: item?.houseInfo?.buildingName + item?.houseInfo?.unitName + item?.houseInfo?.floorName + item?.houseInfo?.name,
        houseId: item?.houseId
      }
      history.push(`/PayMoney${window.location.search}`, values)
    } else {
      Request.get(Path.getMsgTemplate, { params: { id: 4 } }).then(v => {
        if (v.success && v.data) {
          const news = {
            link: `${v.data?.url}&token=${userData?.token}`, //H5消息页面url 必填
            title: v.data?.title, //H5消息标题
            desc: v.data?.detail, //H5消息摘要
            imgUrl: v.data?.picurl, //H5消息封面图片URL
          }
          if (window.WeixinJSBridge) {
            window.WeixinJSBridge.invoke('sendChatMessage', {
              msgtype: "news", //消息类型，必填
              enterChat: true, //为true时表示发送完成之后顺便进入会话，仅移动端3.1.10及以上版本支持该字段
              news,
            }, function (res) {
              if (res.err_msg == 'sendChatMessage:ok') {
                //发送成功
              } else {
                console.log('分享失败');
              }
            })
          } else {
            wx.invoke('sendChatMessage', {
              msgtype: "news", //消息类型，必填
              enterChat: true, //为true时表示发送完成之后顺便进入会话，仅移动端3.1.10及以上版本支持该字段
              news,
            }, function (res) {
              if (res.err_msg == 'sendChatMessage:ok') {
                //发送成功
              } else {
                console.log('分享失败');
              }
            })
          }
        } else {
          Toast.info('分享失败!', 2)
        }
      })
    }
  }
  return (
    <div style={{ height: '100vh' }}>
      {paymentList.length > 0 ? <List style={{ height: 'calc(100% - 30px)', margin: '0 15px', overflow: 'auto' }} className='houseList'>
        {paymentList && paymentList.map(item => (
          <div key={item.houseId} className={Style.paymentBackground}>
            <div>
              <div style={{ display: 'flex', marginBottom: '4px' }}>
                <img src={HouseIcon} style={{ width: '22px', height: '22px', marginRight: '13px' }} />
                <span>{item?.houseInfo?.communityName}</span>
              </div>
              <div className={Style.paymentContent} style={{marginBottom: '10px'}}>
                {item?.houseInfo?.buildingName + item?.houseInfo?.unitName + item?.houseInfo?.floorName + item?.houseInfo?.name}
                <span className={Style.paymentContent} style={{color: '#00AAFF', marginLeft: '8px'}}>{item?.customerName}</span>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img src={ZhangDan} style={{ width: '22px', height: '22px', marginRight: '13px' }} />
                <span>{`¥${Fmoney(item?.amount)}`}</span>
              </div>
            </div>
            <div className={Style.divBoxButton} onClick={() => payMoney(item)}>{token ? '我要缴费' : '费用催缴'}</div>
          </div>
        ))}
      </List> : flag ?
        <Divider style={{ borderStyle: 'dashed' }}>
          该用户未绑定房屋或已缴清物业费
        </Divider>: ''
      }
      {
        (paymentList.length > 0 && token) &&
        <div style={{ fontSize: '14px', color: '#00AAFF', height: '30px', textAlign: 'center', lineHeight: '30px' }} onClick={() => history.push(`/PaymentRecord${window.location.search}`)}>查看缴费记录</div>
      }
    </div>
  )
}

export default PropertyPay
