import { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom"
import { ImageViewer, Space, Divider, Image } from 'antd-mobile-v5';
import { EyeOutline } from 'antd-mobile-icons'
import { getQueryString } from '../../../utils/utils'
import Request from '../../../utils/request'
import Path from '../../../utils/path'
import DefaultHearder from '../../../images/defaultHearder.png'

const DetailArticle = () => {
  let history = useHistory()
  // 管家token
  const token = getQueryString('employeetoken')
  const articleId = getQueryString('articleId')

  document.title = '物业瞬间'

  const [dataObj, setDataObj] = useState({})
  const [flag, setFlag] = useState(false)

  useEffect(() => {
    Request.get(Path.searchByIdInfoMoment, { params: { id: articleId } }).then(v => {
      if (v.success && v.data) {
        setDataObj(v.data)
      }
      setFlag(true)
    })
  }, [])

  return (
    <div style={{ height: '100vh', padding: '15px', overflowY: 'auto', background: '#fff' }}>
      {dataObj?.id ? <><div style={{ display: 'flex', alignItems: 'center' }}>
        <img src={dataObj?.userHeadPic || DefaultHearder} style={{ width: '50px', height: '50px', borderRadius: '50%' }} />
        <div style={{ marginLeft: '20px' }}>
          <div style={{ marginBottom: '5px' }}>{dataObj?.userName}</div>
          <div style={{ color: '#999' }}>{dataObj?.issueTime}</div>
        </div>
      </div>
        <div style={{ padding: '0 15px' }}>
          <div style={{ margin: '15px 0', lineHeight: '25px' }}>
            {dataObj?.content}
          </div>
          {(Array.isArray(dataObj.pictureList) && dataObj?.pictureList.length > 0) && <Space wrap style={{ marginBottom: '10px' }}>
            {dataObj.pictureList.map((item, index) => (
              <Image key={index} src={item} width={80} height={80} style={{ borderRadius: 8 }} onClick={() => ImageViewer.Multi.show({ images: dataObj.pictureList, defaultIndex: index })} />
            ))}
          </Space>}
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '5px' }}>
            <div style={{ color: '#0072BA' }}>{dataObj?.projectName}</div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <EyeOutline fontSize={20} />
              <span style={{ marginLeft: '5px' }}>{dataObj?.total}</span>
            </div>
          </div>
        </div></> : flag ?
        <Divider style={{ borderStyle: 'dashed' }}>
          该信息已被删除
        </Divider> : ''
      }
    </div>
  )
}

export default DetailArticle
