import { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom"
import { Toast } from 'antd-mobile'
import { getQueryString } from '../../../utils/utils'
import Request from '../../../utils/request'
import Path from '../../../utils/path'

const YunZhiJia = () => {
  let history = useHistory()
  // 管家token
  // const token = getQueryString('employeetoken')
  const yunphone = getQueryString('yunemployeephone') || ''

  useEffect(() => {
    Request.get(Path.yunLogin, { params: { email: yunphone } }).then(d => {
      if (d.success && d.data) {
        Request.get(Path.getMainVersionByEid, { params: { at: d.data } }).then(at => {
          if (at.success && at.data) {
            history.push(`/frontPage${window.location.search}&at=${at.data}`)
          } else {
            history.push(`/yLogin${window.location.search}`)
          }
        })
      } else {
        Toast.hide()
        history.push(`/yLogin${window.location.search}`)
      }
    })
  }, [])
  return (
    <div>
    </div>
  )
}

export default YunZhiJia
