import { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom"
import { Grid, Card } from 'antd-mobile-v5'
import { DownOutline, UpOutline } from 'antd-mobile-icons'
import { getQueryString } from '../../../utils/utils'
import Request from '../../../utils/request'
import Path from '../../../utils/path'
import ArticleIcon from '../../../images/articleIcon.png'
import RepairIcon from '../../../images/repairIcon.png'
import HousesIcon from '../../../images/housesIcon.png'
import PayIcon from '../../../images/payIcon.png'

const Employee = () => {
  let history = useHistory()

  // 管家token
  const token = getQueryString('employeetoken')

  document.title = '诚信行'

  const [upOrDown, setUpOrDown] = useState(false)
  const [data, setData] = useState({buildList:[]})
  const [myData, setMyData] = useState({})
  const [workData, setWorkData] = useState({})

  useEffect(() => {
    Request.get(Path.getTotalNodeBuild, { headers: { authorization: 'Bearer ' + token } }).then(v => {
      if (v.success && v.data) {
        setData(v.data)
      }
    })

    Request.get(Path.getTotalWorkOrderInfo, { headers: { authorization: 'Bearer ' + token } }).then(v => {
      if (v.success && v.data) {
        setWorkData(v.data)
      }
    })

    Request.get(Path.getTotalHouseUser, { headers: { authorization: 'Bearer ' + token } }).then(v => {
      if (v.success && v.data) {
        setMyData(v.data)
      }
    })
  }, [])

  const houseInfo = () => {
    const communityId = data?.communityId
    const buildList = data?.buildList.map(item=>item.buildId)
    history.push(`/employeeHouse${window.location.search}`, {communityId, buildList})
  }

  return (
    <div style={{ margin: '10px 15px' }}>
      <Card title={`经营单元 (${data?.communityName})`}>
        <div style={{ display: 'flex', marginBottom: '20px' }}>
          <div style={{ flex: 1, textAlign: 'center' }}>
            <div style={{ fontSize: '16px', marginBottom: '10px' }}>{data?.buildCount}</div>
            <div>总楼栋</div>
          </div>
          <div style={{ flex: 1, textAlign: 'center' }}>
            <div style={{ fontSize: '16px', marginBottom: '10px' }}>{data?.userCount}</div>
            <div>总户数</div>
          </div>
        </div>
        {
          (upOrDown && Array.isArray(data.buildList)) && data.buildList.map(item => (
            <div key={item?.buildId} style={{ display: 'flex', justifyContent: 'space-between', padding: '5px 15px' }}>
              <span>{item?.buildName}</span>
              <span>{item?.userHold || 0}户</span>
            </div>
          ))
        }
        <div style={{ marginTop: '15px', textAlign: 'center' }} onClick={() => setUpOrDown(!upOrDown)}>
          {upOrDown ? <UpOutline fontSize={12} /> : <DownOutline fontSize={12} />}
        </div>
      </Card>
      <Card style={{ marginTop: '15px', padding: '15px' }}>
        <Grid columns={4} gap={8}>
          <Grid.Item style={{ textAlign: 'center', fontSize: '12px' }}>
            <img style={{ width: '31px', height: '31px' }} src={HousesIcon} onClick={() => history.push(`/houseReview${window.location.search}`)} />
            <div style={{ marginTop: '5px' }}>房屋认证</div>
          </Grid.Item>
          <Grid.Item style={{ textAlign: 'center', fontSize: '12px' }}>
            <img style={{ width: '31px', height: '31px' }} src={RepairIcon} onClick={() => history.push(`/valetRepair${window.location.search}`)} />
            <div style={{ marginTop: '5px' }}>代客报修</div>
          </Grid.Item>
          <Grid.Item style={{ textAlign: 'center', fontSize: '12px' }} onClick={() => history.push(`/payStatis${window.location.search}`)}>
            <img style={{ width: '31px', height: '31px' }} src={PayIcon} />
            <div style={{ marginTop: '5px' }}>物业缴费</div>
          </Grid.Item>
          <Grid.Item style={{ textAlign: 'center', fontSize: '12px' }} onClick={() => history.push(`/addArticle${window.location.search}`)}>
            <img style={{ width: '31px', height: '31px' }} src={ArticleIcon} />
            <div style={{ marginTop: '5px' }}>物业瞬间</div>
          </Grid.Item>
        </Grid>
      </Card>
      <Card title='我的工单' style={{ marginTop: '15px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <div style={{ flex: 1, textAlign: 'center' }}>
            <div style={{ fontSize: '16px', marginBottom: '10px', color: '#ff0000' }}>{workData?.complaintCount}</div>
            <div>投诉处理</div>
          </div>
          <div style={{ flex: 1, textAlign: 'center' }} onClick={() => history.push(`/workOrder${window.location.search}`)}>
            <div style={{ fontSize: '16px', marginBottom: '10px', color: '#ff8300' }}>{workData?.repairCount}</div>
            <div>报修处理</div>
          </div>
          <div style={{ flex: 1, textAlign: 'center' }}>
            <div style={{ fontSize: '16px', marginBottom: '10px', color: '#0026ff' }}>{workData?.patrolCount}</div>
            <div>巡视巡查</div>
          </div>
        </div>
      </Card>
      <Card title='我的数据' style={{ marginTop: '15px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '5px' }} onClick={()=>houseInfo()}>
          <div style={{ flex: 1, textAlign: 'center' }}>
            <div style={{ fontSize: '16px', marginBottom: '10px' }}>{myData?.approveHouse || 0}</div>
            <div>认证房屋</div>
          </div>
          <div style={{ flex: 1, textAlign: 'center' }}>
            <div style={{ fontSize: '16px', marginBottom: '10px' }}>{myData?.approveUser || 0}</div>
            <div>认证用户</div>
          </div>
          <div style={{ flex: 1, textAlign: 'center' }}>
            <div style={{ fontSize: '16px', marginBottom: '10px' }}>{myData?.houseRate || '0%'}</div>
            <div>房屋认证率</div>
          </div>
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '35px' }}>
          <div style={{ flex: 1, textAlign: 'center' }}>
            <div style={{ fontSize: '16px', marginBottom: '10px' }}>{myData?.customer || 0}</div>
            <div>我的客户</div>
          </div>
          <div style={{ flex: 1, textAlign: 'center' }}>
            <div style={{ fontSize: '16px', marginBottom: '10px' }}>{myData?.groupChat || 0}</div>
            <div>我的群聊</div>
          </div>
          <div style={{ flex: 1, textAlign: 'center' }}>
            <div style={{ fontSize: '16px', marginBottom: '10px' }}>{myData?.customerRate || '0%'}</div>
            <div>客户添加率</div>
          </div>
        </div>
      </Card>
    </div>
  )
}

export default Employee
