import { useEffect, useState } from 'react'
import { Card, Loading, InfiniteScroll, SearchBar, List, Tabs } from 'antd-mobile-v5'
import { getQueryString, Trim, Fmoney } from '../../../utils/utils'
import Request from '../../../utils/request'
import Path from '../../../utils/path'

let count = 0
const FeeCall = () => {
  const wx = window.jWeixin
  let flagWX = wx
  if (window.WeixinJSBridge) {
    flagWX = window.WeixinJSBridge
  }
  // 管家token
  const token = getQueryString('employeetoken')
  // 企业微信应用id
  const agentid = getQueryString('agentid')

  document.title = '费用催缴'
  const [data, setData] = useState([])
  const [nameValue, setNameValue] = useState('')
  const [system, setSystem] = useState(true)

  const [activeKey, setActiveKey] = useState('2')
  const [hasMore, setHasMore] = useState(false)
  const [page, setPage] = useState({ pageTotal: 1, pageNo: 0 })

  useEffect(() => {
    Request.get(Path.jsapiSign, { params: { url: window.location.href.split('#')[0], agentId: agentid } }).then(res => {
      if (res.success) {
        const { appId, nonceStr, signature, timestamp } = res.data
        wx.config({
          beta: true,  // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId, // 必填，公众号的唯一标识
          timestamp, // 必填，生成签名的时间戳
          nonceStr, // 必填，生成签名的随机串
          signature,// 必填，签名
          jsApiList: ["openEnterpriseChat"], // 必填，需要使用的JS接口列表
        })
        wx.ready(function () {
          console.log('config成功')
          // wx.hideOptionMenu()
          // myAgentConfig()
        })
        wx.error(function (res) {
          console.log('config失败')
        })
      }
    })
  }, [])

  useEffect(() => {
    let userAgent = navigator.userAgent
    if (userAgent.includes('iPhone') || userAgent.includes('iPad')) {
      setSystem(false)
    }
  }, [])

  const myAgentConfig = () => {
    Request.get(Path.agentJsapiSign, { params: { url: window.location.href.split('#')[0], agentId: agentid } }).then(res => {
      if (res.success) {
        const { appId, nonceStr, signature, timestamp } = res.data
        wx.agentConfig({
          corpid: appId, // 必填，企业微信的corpid，必须与当前登录的企业一致
          agentid, // 必填，企业微信的应用id
          timestamp, // 必填，生成签名的时间戳
          nonceStr, // 必填，生成签名的随机串
          signature,// 必填，签名
          jsApiList: ['shareToExternalContact'], // 必填，需要使用的JS接口列表
          success: function (res) {
            // 回调
            // openChat()
          },
          fail: function (res) {
            console.log('myAgentConfig失败')
          }
        })
      }
    })
  }

  useEffect(() => {
    count = 0
    setData([])
    setHasMore(false)
    setPage({ pageTotal: 1, pageNo: 0 })
    getListData(1)
  }, [nameValue, activeKey])

  const getListData = async (pageNo) => {
    const name = nameValue || undefined
    await Request.get(Path.getTotalBuildOwner, { params: { pageSize: 15, pageNo, type: activeKey, name }, headers: { authorization: 'Bearer ' + token } }).then(res => {
      if (res.success && Array.isArray(res.data.data)) {
        setPage({ pageTotal: res?.data?.pageTotal, pageNo: res?.data?.pageNo })
        setData(val => [...val, ...res?.data?.data])
        if (count < res?.data?.pageTotal && res?.data?.pageTotal > 1) {
          setHasMore(true)
        } else {
          setHasMore(false)
        }
      }
    })
  }

  const openChat = externalUserId => {
    wx.openEnterpriseChat({
      // 注意：userIds和externalUserIds至少选填一个。内部群最多2000人；外部群最多500人；如果有微信联系人，最多40人
      // userIds: '',    //参与会话的企业成员列表，格式为userid1;userid2;...，用分号隔开。
      externalUserIds: `${externalUserId}`, // 参与会话的外部联系人列表，格式为userId1;userId2;…，用分号隔开。
      groupName: '',  // 会话名称。单聊时该参数传入空字符串""即可。
      chatId: "", // 若要打开已有会话，需指定此参数。如果是新建会话，chatId必须为空串
      success: function (res) {
        // var chatId = res.chatId; //返回当前群聊ID，仅当使用agentConfig注入该接口权限时才返回chatId
        // 回调
      },
      fail: function (res) {
        if (res.errMsg.indexOf('function not exist') > -1) {
          alert('版本过低请升级')
        }
      }
    })
  }

  const loadMore = async () => {
    if (count>0 && count < page.pageTotal) {
      await getListData(++count)
    } else {
      count++
    }
  }

  const InfiniteScrollContent = ({ hasMore }) => {
    return (
      <>
        {page.pageTotal > 0 ? hasMore ? (
          <>
            <span>Loading</span>
            <Loading />
          </>
        ) : (
          <span>--- 我是有底线的 ---</span>
        ) : (
          <span>--- 暂无数据 ---</span>
        )}
      </>
    )
  }

  return (
    <div style={{ height: '100vh', overflow: 'hidden' }}>
      <Card style={{ borderRadius: 0 }}>
        <SearchBar placeholder='请输入业主姓名'
          onSearch={val => setNameValue(Trim(val))} />
      </Card>
      <div>
        <Tabs activeKey={activeKey} onChange={key => setActiveKey(key)}>
          <Tabs.TabPane title='微信客户账单' key='2' />
          <Tabs.TabPane title='房屋费用账单' key='1' />
        </Tabs>
      </div>
      <List style={{ background: 'transparent', border: 0 }}>
        <div style={{ display: 'flex', alignItems: 'center', background: '#fff', padding: '10px 0', paddingLeft: '15px', fontSize: '14px' }}>
          <span style={{ flex: 1 }}>姓名</span>
          <span style={{ flex: 2, textAlign: 'center' }}>房间</span>
          <span style={{ flex: 1, textAlign: 'center' }}>欠费金额</span>
          {activeKey == 2 && <span style={{ flex: 1, textAlign: 'center' }}>操作</span>}
        </div>
        <div style={{ overflow: 'auto', height: 'calc(100vh - 129px)' }}>
          {
            data.map((item, index) => (
              <div key={index} style={{ display: 'flex', alignItems: 'center', background: '#fff', padding: '15px 0', paddingLeft: '15px', fontSize: '14px' }}>
                <span style={{ flex: 1 }}>{item?.userName}</span>
                <span style={{ flex: 2, textAlign: 'center' }}>{item?.houseName}</span>
                <span style={{ flex: 1, textAlign: 'center' }}>{Fmoney(item?.amount)}元</span>
                {activeKey == 2 && <span style={{ flex: 1, textAlign: 'center', color: '#1890ff' }} onClick={() => openChat(item?.externalUserId)}>{system ? '去聊天' : ''}</span>}
              </div>
            ))
          }
          <InfiniteScroll loadMore={() => loadMore()} hasMore={hasMore} threshold={100}>
            <InfiniteScrollContent hasMore={hasMore} />
          </InfiniteScroll>
        </div>
      </List>
    </div>
  )
}

export default FeeCall
