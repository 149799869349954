import { useEffect } from 'react';
import { Form, Input, Button } from 'antd-mobile-v5';
import { useHistory } from "react-router-dom"
import Request from '../../../../utils/request'
import { getQueryString } from '../../../../utils/utils'
import Path from '../../../../utils/path'
import CryptoJS from 'crypto-js';

const YLogin = () => {
  let history = useHistory()
  const phone = getQueryString('yunemployeephone') || ''
  
  document.title = '登录'

  const encryption = values => {
    let t = values.password
    let e = values.email
    let r = CryptoJS.enc.Utf8.parse(e);
    let n = CryptoJS.enc.Utf8.parse(e)
    let encrypt = CryptoJS.DES.encrypt(t, r, {
      iv: n,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    })
    const enPassword = encrypt.ciphertext.toString(CryptoJS.enc.Base64)
    // console.log('enPassword ', enPassword);
    return enPassword
  }
  const onFinish = (values) => {
    values.password = encryption(values)

    Request.get(Path.yunLogin, { params: { email: values.email, cryptoPassword: values.password } }).then(d => {
      if (d.success && d.data) {
        Request.get(Path.getMainVersionByEid, { params: { at: d.data } }).then(at => {
          if (at.success && at.data) {
            history.push(`/frontPage${window.location.search}&at=${at.data}`)
          }
        })
      }
    })
  }
  return (
    <div style={{ margin: '10px' }}>
      <h3 style={{textAlign: 'center'}}>登录云之家</h3>
      <Form className='myForm'
        onFinish={onFinish}
        initialValues={{email: phone}}
        footer={
          <Button block type='submit' color='primary' size='large'>
            提交
          </Button>
        }
      >
        <Form.Item
          name='email'
          label='账号(手机号)'
          rules={[{ required: true, message: '账号不能为空' }]}
        >
          <Input disabled={phone} placeholder='请输入账号' clearable />
        </Form.Item>
        <Form.Item label='密码' name='password' rules={[{ required: true, message: '账号不能为空' }]}>
          <Input placeholder='请输入密码' clearable type='password' />
        </Form.Item>
      </Form>
    </div>
  )
}

export default YLogin
